import { InlineIcon } from '@iconify/react';
import avatar from '@/assets/images/png/password.png';
import {  Button,  Divider,  Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { ToastGeneric } from "@/utils/toast";
import * as yup from 'yup';
import { useFormik } from 'formik';
import './ChangePasswordForm.style.scss';
import { editUserPasswordAsync } from '@/store/reducers/user/user.action';
import { createStructuredSelector } from "reselect";
import { connect } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';

const ChangePasswordFormPage: React.FC<any> = ({
 userConnected,
 editUserPasswordAsync
}) => {



  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const handleClickShowPasswordOne = () => setShowPasswordOne(!showPasswordOne);
  const handleMouseDownPasswordOne = () => setShowPasswordOne(!showPasswordOne);

  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const handleClickShowPasswordTwo = () => setShowPasswordTwo(!showPasswordTwo);
  const handleMouseDownPasswordTwo = () => setShowPasswordTwo(!showPasswordTwo);

    const validationSchema = yup.object({
        ancien_mot_passe: yup.string().required(" L'ancien mot de passe de la liste est obligatoire "),
        new_mot_passe: yup.string().required(" Le nouveau mot de passe de la liste est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: userConnected ? userConnected.user.id : null,
            ancien_mot_passe: '',
            new_mot_passe: '',
            email: userConnected ? userConnected.user.email : '',
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
          console.log('userConnected',userConnected);
          
          if (values.ancien_mot_passe && values.new_mot_passe && values.ancien_mot_passe !== values.new_mot_passe) {
            editUserPasswordAsync(values)
          } else {
            ToastGeneric('Les mots de passe doivent etre differents', 'error', 3000).fire();
          }
        }
    });



    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> Modification du profil</span>

            <div className="avatar">
                <img src={avatar} alt="" />
            </div>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                <TextField  
                      variant="outlined"
                      name="ancien_mot_passe"
                      label="Ancien mot de passe"
                      className='my-text-field'
                      onChange={formik.handleChange}
                      value={formik.values.ancien_mot_passe}
                      error={formik.touched.ancien_mot_passe && Boolean(formik.errors.ancien_mot_passe)}
                      type={showPasswordOne ? "text" : "password"}
                      InputProps={{ 
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="ei:lock" className='icon-lock'/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordOne}
                              onMouseDown={handleMouseDownPasswordOne}
                            >
                              {showPasswordOne ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{marginTop:'15px'}} 
                      />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                <TextField  
                      variant="outlined"
                      name="new_mot_passe"
                      label="Nouveau mot de passe"
                      className='my-text-field'
                      onChange={formik.handleChange}
                      value={formik.values.new_mot_passe}
                      error={formik.touched.new_mot_passe && Boolean(formik.errors.new_mot_passe)}
                      type={showPasswordTwo ? "text" : "password"}
                      InputProps={{ 
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="ei:lock" className='icon-lock'/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle passwordTwo visibility"
                              onClick={handleClickShowPasswordTwo}
                              onMouseDown={handleMouseDownPasswordTwo}
                            >
                              {showPasswordTwo ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{marginTop:'15px'}} 
                      />
                </Grid>
            </Grid>


      <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option-profil'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:edit-pencil" />}
                    variant='contained'>
                    Modifier
                </Button>

                {/* <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    variant='contained'>
                    Réinitialiser
                </Button> */}
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    editUserPasswordAsync: (data:any) => dispatch(editUserPasswordAsync(data)),
  });
  
  const connector = connect(mapStateToProps,mapDispatchToProps);
  const ChangePasswordForm = connector(ChangePasswordFormPage);

export default ChangePasswordForm

