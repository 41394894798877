import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoadingAuth, getUserToken, getOtpCode, catchOtpData } from "@/store/reducers/auth/auth.action";


export class AuthService {

  static authenticate(data:any) {
    console.log("data from service",data);
    axios.post('/auth',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            if (typeof response.message === 'object' && response.message.message) {
                // Si response.message est un objet avec une propriété message
                ToastGeneric(response.message.message, 'error', 3000).fire();
            } else {
                // Sinon, affichez directement response.message
                ToastGeneric(response.message, 'error', 3000).fire();
            }

        }else{

            console.log("Not error ---> ");
            store.dispatch(getUserToken(response.data));
            // store.dispatch(getUser(response.data));
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoadingAuth(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }

  static passwordForgotten(data:any) {

    axios.post('/auth/forgot-password',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(`Veuillez saisir un email`, 'error', 3000).fire();
            store.dispatch(getOtpCode(false));
        }else{

            console.log("Not error ---> ");
            ToastGeneric(`Code OTP envoyé. Consulter votre boite email`, 'success', 1000).fire();
            store.dispatch(getOtpCode(true));
            store.dispatch(catchOtpData(response.data));
            store.dispatch(setLoadingAuth(false));

        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }

  static updatePassword(data:any) {

    axios.post('/auth/update-password',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            if (typeof response?.message === 'object' && response?.message?.message) {
                // Si response.message est un objet avec une propriété message
                ToastGeneric(response?.message?.message, 'error', 3000).fire();
            } else {
                // Sinon, affichez directement response.message
                ToastGeneric(response?.message, 'error', 3000).fire();
            }

        }else{

            console.log("Not error ---> ");
            // store.dispatch(getUser(response.data));
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoadingAuth(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }

  static resetPassword(data:any) {

    axios.post('/user/reset-password',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            if (typeof response?.message === 'object' && response?.message?.message) {
                // Si response.message est un objet avec une propriété message
                ToastGeneric(response?.message?.message, 'error', 3000).fire();
            } else {
                // Sinon, affichez directement response.message
                ToastGeneric(response?.message, 'error', 3000).fire();
            }

        }else{

            console.log("Not error ---> ");
            // store.dispatch(getUser(response.data));
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoadingAuth(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }


}