import { EnginService } from "../../../services/engin.service";
import { enginTypes } from "./engin.types";

export const setLoading = (loading:any) => ({
    type: enginTypes.LOADING,
    payload: loading,
});

export const getAllEngins = (list:any) => ({
    type: enginTypes.GET_ENGIN_LIST,
    payload: list,
});

export const getUtilisationByEngin = (list:any) => ({
  type: enginTypes.GET_UTILISATION_BY_ENGIN,
  payload: list,
});

export const getAllIncidents = (list:any) => ({
  type: enginTypes.GET_INCIDENT_LIST,
  payload: list,
});

export const selectedItem = (engin:any) => ({
  type: enginTypes.SELECTED_ENGIN,
  payload: engin,
});

export const selectSyntheseEngin = (syntheseEngin:any) => ({
  type: enginTypes.GET_SYNTHESE_ENGIN,
  payload: syntheseEngin,
});


 
export const  getAllEnginsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.getEngins();
};

export const  getAllEnginsIncidentAsync =  () =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    EnginService.getEnginsIncident();
  };

export const  getUtilisationByEnginAsync =  (id:number) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    EnginService.getUtilisationByEngin(id);
  };

export const  createUtilisationEnginAsync =  (engin:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.createUtilisationEngin(engin);
};

export const  createNoWorkEnginAsync =  (engin:any) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    EnginService.createNoWorkEngin(engin);
  };
  

export const  updateUtilisationEnginAsync =  (engin:any) =>
(dispatch:any) => {
  console.log("engin to service", engin);
  dispatch(setLoading(true));
  EnginService.updateUtilisationEngin(engin);
};

export const  createIncidentEnginAsync =  (engin:any) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    EnginService.createIncidentEngin(engin);
  };
  
  export const  updateIncidentEnginAsync =  (engin:any) =>
  (dispatch:any) => {
    console.log("engin to service", engin);
    dispatch(setLoading(true));
    EnginService.updateIncidentEngin(engin);
  };
  





