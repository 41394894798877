import incidentTypes from "./incident.types";

interface IIncidentReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const incidentReducer = (
  state: IIncidentReducerState = INITIAL_STATE,
  action: any
): IIncidentReducerState => {
  switch (action.type) {
    case incidentTypes.GET_INCIDENT_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case incidentTypes.SELECTED_INCIDENT:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case incidentTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default incidentReducer;
