import React from 'react';
import ResetPasswordForm from '../page-contents/ResetPasswordForm/ResetPasswordForm';


const ResetPassword: React.FC = () => {
  return (
    <>
      <ResetPasswordForm />
    </>
  )
}

export default ResetPassword;
