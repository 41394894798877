import dashboardTypes from "./dashboard.types";

interface IDashboardReducerState {
  list: any,
  user: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: {},
  user: {},
  loading: false,
  error: ""
};

const dashboardReducer = (
  state: IDashboardReducerState = INITIAL_STATE,
  action: any
): IDashboardReducerState => {
  switch (action.type) {
    case dashboardTypes.GET_DASHBOARD_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };
    
    case dashboardTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    case dashboardTypes.GET_DASHBOARD_USER:
      return {
        ...state,
        user: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default dashboardReducer;
