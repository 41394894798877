import { AlerteService } from "../../../services/alerte.service";
import { alerteTypes } from "./alerte.types";

export const setLoading = (loading:any) => ({
    type: alerteTypes.LOADING,
    payload: loading,
});

export const getAllAlertes = (list:any) => ({
    type: alerteTypes.GET_ALERTE_LIST,
    payload: list,
});

export const getAllNumberOfAlertes = (count:any) => ({
  type: alerteTypes.GET_ALERTE_NUMBER,
  payload: count,
});

export const selectedItem = (alerte:any) => ({
  type: alerteTypes.SELECTED_ALERTE,
  payload: alerte,
});

 
export const  getAllAlertesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AlerteService.getAlertes();
};

export const  getAllCountAlertesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AlerteService.getCountAlertes();
};




