import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectAuth = (state: TRootState) => state.auth;

export const selectAuthUser = createSelector(
  [selectAuth],
  (auth:any) => auth.user
);

export const selectAuthPass = createSelector(
  [selectAuth],
  (auth:any) => auth.password
);

export const selectAuthLoading = createSelector(
  [selectAuth],
  (auth:any) => auth.loading
);

export const selectAuthRememberMe = createSelector(
  [selectAuth],
  (auth:any) => auth.rememberMe
);

export const selectAuthErrors = createSelector(
  [selectAuth],
  (auth:any) => auth.errors
);

export const selectAuthToken = createSelector(
  [selectAuth],
  (auth:any) => auth.jwt
);

export const selectOtpCodeStatus = createSelector(
  [selectAuth],
  (auth:any) => auth.isOptSent
);

export const getOtpData = createSelector(
  [selectAuth],
  (auth:any) => auth.otpData
);
