import { PersonnelService } from "../../../services/personnel.service";
import { personnelTypes } from "./personnel.types";

export const setLoading = (loading:any) => ({
    type: personnelTypes.LOADING,
    payload: loading,
});

export const getAllPersonnels = (list:any) => ({
    type: personnelTypes.GET_PERSONNEL_LIST,
    payload: list,
});

export const getAbsenceByPerson = (list:any) => ({
  type: personnelTypes.GET_ABSENCE_BY_PERSON,
  payload: list,
});

export const selectedItem = (personnel:any) => ({
  type: personnelTypes.SELECTED_PERSONNEL,
  payload: personnel,
});

export const selectSynthesePersonnel = (synthesePersonnel:any) => ({
  type: personnelTypes.GET_SYNTHESE_PERSONNEL,
  payload: synthesePersonnel,
});

export const selectHistoriquePersonnel = (historiquePersonnel:any) => ({
  type: personnelTypes.GET_HISTORIQUE_PERSONNEL,
  payload: historiquePersonnel,
});

 
export const  getAllPersonnelsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  PersonnelService.getPersonnels();
};

export const  getAbsenceByPersonAsync =  (id:number) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    PersonnelService.getAbsenceByPerson(id);
  };

export const  createAbsentPersonnelAsync =  (personnel:any) =>
(dispatch:any) => {

  PersonnelService.createAbsentPersonnel(personnel);
  dispatch(setLoading(true));
};

export const  updateAbsentPersonnelAsync =  (personnel:any) =>
(dispatch:any) => {
  console.log("personnel to service", personnel);
  dispatch(setLoading(true));
  PersonnelService.updateAbsentPersonnel(personnel);
};

export const  deletePersonnelAsync =  (personnel:any) =>
(dispatch:any) => {
  console.log("personnel to service", personnel);
  dispatch(setLoading(true));
  PersonnelService.deletePersonnel(personnel);
};

export const  getHistoriquePersonnelsAsync =  (data: any) =>
(dispatch:any) => {
  console.log('getHistoriquePersonnelsAsync')
  dispatch(setLoading(true));
  const val = { 
    dateDebut:data.dateDebut,
    dateFin:data.dateFin,
    chantierId: (!data.chantierId || data.chantierId === '') ?  null: data.chantierId,
    personnelId: (!data.personnelId || data.personnelId === '') ?  null: data.personnelId,
  }
  console.log('getHistoriquePersonnelsAsync', val)
  PersonnelService.getHistoriquePersonnel(val);
};



