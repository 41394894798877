import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllCuves, getCuveDetail, getAllCuvesAsync } from "@/store/reducers/cuve/cuve.action";


export class CuveService {

  static getCuves() {
    axios.get(`/cuve/terrain?chantierId=${localStorage.getItem("chantierId")}`).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllCuves(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getCuveDetail() {
    axios.get(`/cuve-detail?id=${localStorage.getItem("chantierId")}`).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getCuveDetail(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }


  static approvisionCuve(data:any) {

    axios.post('/cuve/approvisionnement',data).then((response:any)=>{
        console.log('approvisionnement',response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllCuvesAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static retraitCuve(data:any) {

    axios.post('/cuve/retrait',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllCuvesAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteCuve(data:any) {

    axios.patch('/delete/cuve',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            // store.dispatch(getAllCuvesAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}