import natureTravauxTypes from "./natureTravaux.types";

interface INatureTravauxReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const natureTravauxReducer = (
  state: INatureTravauxReducerState = INITIAL_STATE,
  action: any
): INatureTravauxReducerState => {
  switch (action.type) {
    case natureTravauxTypes.GET_NATURE_TRAVAUX_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case natureTravauxTypes.SELECTED_NATURE_TRAVAUX:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case natureTravauxTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default natureTravauxReducer;
