import {  Box, Grid } from '@mui/material';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './RapportEngin.content.style.scss';
import DashboardFilter from '@/features/admin-iveqi/components/Dashboard-filter/DashboardFilter';
import DashboardChartCard from '@/features/admin-iveqi/components/Dashboard-chart-card/DashboardChartCard';
import { selectHistoriqueChantierList } from '@/store/reducers/chantier/chantier.selector';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";


const CtRapportEnginCampaignPage: React.FC<any> = ({
  historiqueChantier
}) =>  {

  console.log('historiqueChantier CtHistoriqueCampaignPage', historiqueChantier);
  return (
    <>
      <DashboardFilter />
      <PerfectScrollbar className="ct-dashboard">
        <Box>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <DashboardChartCard historiqueChantiers={historiqueChantier}  />
              </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  historiqueChantier: selectHistoriqueChantierList,
});

const connector = connect(mapStateToProps);
const CtRapportEnginCampaign = connector(CtRapportEnginCampaignPage);

export default CtRapportEnginCampaign;
