export enum chantierTypes {
    LOADING = "LOADING",
    CREATE_CHANTIER = "CREATE_CHANTIER",
    UPDATE_CHANTIER = "UPDATE_CHANTIER",
    REMOVE_CHANTIER = "REMOVE_CHANTIER",
    SELECTED_CHANTIER = "SELECTED_CHANTIER",
    GET_CHANTIER_LIST = "GET_CHANTIER_LIST",
    GET_PERSONNEL_CHANTIER = "GET_PERSONNEL_CHANTIER",
    GET_ENGIN_CHANTIER = "GET_ENGIN_CHANTIER",
    GET_SYNTHESE_CHANTIER = "GET_SYNTHESE_CHANTIER",
    GET_HISTORIQUE_CHANTIER = "GET_HISTORIQUE_CHANTIER",
}
  
export default chantierTypes;