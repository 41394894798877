import React from 'react';
import { routePaths } from '@/config';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField } from '@mui/material';
import { Link } from "react-router-dom";
import './SigninForm.styles.scss';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { useAppDispatch } from '@/store';
import logoApp from "@/assets/images/png/logo.png";
import { TSignIn } from '@/features/auth/types';
// import { updateUserConnected } from '@/store/reducers/user/user.action';
import { Icon } from '@iconify/react';

//redux part
// import { store } from '@/store';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectAuthToken } from '@/store/reducers/auth/auth.selector';
import { loginAsync } from '@/store/reducers/auth/auth.action';

const SigninPage:React.FC<any> = ({
  authenticateAsync
}) =>  {

  const [loginData, setLoginData] = useState<TSignIn>({
    login:"",password:"",app:"Web"
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // const dispatch = useAppDispatch();

  const handleChange = (e:any) => {
    setLoginData((values) => ({...values, [e.target.name]: e.target.value}));
  }


  const handleSubmit = async(e:any) => {
    e.preventDefault();
    authenticateAsync(loginData);
    // if (token && ) {
    //   navigate( routePaths.adminIVEQI ,{replace: true});
    //   ToastGeneric(`Vous etes maintenant connectée.`, 'success', 3000).fire();
    //   console.log('token',token);
    // } else {
    //   ToastGeneric(`Informations incorrectes`, 'error', 3000).fire();
    // }
     
      
  }

  return (
    <div className="signin-container">
      <div className="header-part">
        <div className='logo-part'>
          <img src={logoApp} alt="" className="logo-app" />
        </div>
        {/* <span className='title-welcome'>Bienvenue sur</span>
        <span className='title-application-name'>Smart Control</span> */}
        <small className='description'>Connectez-vous pour accéder à la plateforme</small>
      </div>
      <div className="form-part">
          <TextField  placeholder="Votre addresse email ou votre matricule" 
                      variant="outlined"
                      
                      onChange={handleChange}
                      name="login"
                      type="text"
                      InputProps={{ 
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="carbon:user" className='icon-user'/>
                          </InputAdornment>
                        )
                      }}
                      />
          <TextField  placeholder="Votre mot de passe" 
                      variant="outlined"
                      
                      onChange={handleChange}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{ 
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="ei:lock" className='icon-lock'/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{marginTop:'15px'}} 
                      />
          <FormGroup>
            <FormControlLabel color='quartiary' className='remember-me' control={<Checkbox/>} label="Se souvenir de moi" sx={{fontWeight: '300'}}/>
          </FormGroup>
          <Button variant="contained" 
                  color='primary'
                  sx={{marginTop:'30px'}}
                  onClick={handleSubmit}>SE CONNECTER</Button>
      </div>
      <div className="footer-part">
          <Link className="forget-password"
                to={routePaths.authChildren.forgottenPasswordAbsolute}> Mot de passe oublié ?</Link>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  token: selectAuthToken,
});

const mapDispatchToProps = (dispatch:any) => ({
  authenticateAsync: (data:any) => dispatch(loginAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const SigninForm = connector(SigninPage);

export default SigninForm;
