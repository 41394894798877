import { InlineIcon } from "@iconify/react";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./CreatePanneEnginForm.style.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { connect } from "react-redux";
import {
  createIncidentEnginAsync,
  updateIncidentEnginAsync,
} from "@/store/reducers/engin/engin.action";
import { createStructuredSelector } from "reselect";
import "dayjs/locale/fr";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import { selectPersonnelItemList } from "@/store/reducers/personnel/personnel.selector";

//const regexImmatriculation = /[0-9]{1,4}[A-Z]{2}01/

const CreatePanneEnginFormPage: React.FC<any> = ({
  selectedItem,
  createIncidentEnginAsync,
  updateIncidentEnginAsync,
  closeModal,
  userConnected,
  typeOperation,
  getAllPersonnel
}) => {

  console.log("selectedItem", selectedItem);

  const [date, setDate] = useState<Dayjs | null>(
    selectedItem?.date ? dayjs(selectedItem?.date) : dayjs()
  );

  const listPerson: any[] = [];

  getAllPersonnel.forEach((o: any) => {
    listPerson.push({
      ...o,
      value: o.personnel.nom + " " + o.personnel.prenoms,
      id: o.personnel.nom + " " + o.personnel.prenoms,
    });
  });

  const [technicien, setTechnicien] = useState<{ id: any; value: string }>(
    selectedItem
      ? { id: selectedItem?.technicien, value: selectedItem?.technicien }
      : { id: null, value: "" }
  );

  const handleTechnicienChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    if (value === null) {
      setTechnicien({ id: null, value: "" });
    } else {
      setTechnicien(value as { id: any; value: string });
    }
  };

  const validationSchema = yup.object(
    typeOperation === "Declarer une panne"
      ? {
          date_incident: yup.string().required("Champ obligatoire"),
          panne: yup.string().required("Champ obligatoire"),
          observation_panne: yup.string().required("Champ obligatoire"),
        }
      : {
          date_depannage: yup.string().required("Champ obligatoire"),
          nom_technicien: yup.string().required("Champ obligatoire"),
          observation_depannage: yup.string().required("Champ obligatoire"),
        }
  );

  const resetForm = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      date_depannage: date,
      date_incident: date,
      chantierEnginId: selectedItem ? selectedItem?.chantierEnginId : "",
      chantierId: selectedItem ? selectedItem?.chantierId : "",
      enginId: selectedItem ? selectedItem?.id : "",
      id: selectedItem ? selectedItem?.id : "",
      panne: "",
      observation_panne: "",
      compteur_depannage: "",
      nom_technicien: technicien.value,
      observation_depannage: "",
      utilisateurId: userConnected?.user?.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      console.log("Values form", values);

      if (typeOperation === "Declarer une panne") {
        delete values.id;
        delete values.compteur_depannage;
        delete values.observation_depannage;
        delete values.nom_technicien;
        delete values.date_depannage;
        values.date_incident = dayjs(values.date_incident).format('YYYY/MM/DD');

        createIncidentEnginAsync(values);
      } else {
        delete values.date_incident;
        delete values.panne;
        delete values.observation_panne;
        values.date_depannage = dayjs(values.date_depannage).format('YYYY/MM/DD');

        updateIncidentEnginAsync(values);
      }
      closeModal();
    },
  });

  return (
    <form className="create-list-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {typeOperation === "Declarer une panne"
          ? "Declarer une panne"
          : "Modifier une panne"}
      </span>

      {typeOperation === "Declarer une panne" ? (
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  formik.setFieldValue("date_incident", dayjs(newValue));
                }}
                renderInput={(params) => (
                  <TextField {...params} className="my-text-field" />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              placeholder="Panne"
              variant="outlined"
              name="panne"
              label="Panne"
              className="my-text-field"
              onChange={formik.handleChange}
              value={formik.values.panne}
              error={formik.touched.panne && Boolean(formik.errors.panne)}
              // helperText={formik.touched.panne && formik.errors.panne}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              placeholder="Observation"
              variant="outlined"
              name="observation_panne"
              label="Observation"
              className="my-text-field"
              onChange={formik.handleChange}
              value={formik.values.observation_panne}
              error={
                formik.touched.observation_panne &&
                Boolean(formik.errors.observation_panne)
              }
              // helperText={formik.touched.observation_panne && formik.errors.observation_panne}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  formik.setFieldValue("date_depannage", dayjs(newValue));
                }}
                renderInput={(params) => (
                  <TextField {...params} className="my-text-field" />
                )}
              />
            </LocalizationProvider>
          </Grid>

        {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Autocomplete
            id="id-technicien-select"
            options={listPerson}
            value={technicien}
            onChange={handleTechnicienChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value || ''}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Technicien"
                label="Technicien"
                variant="outlined"
                name="nom_technicien"
                onChange={formik.handleChange}
                value={(formik.values.nom_technicien = technicien.value)}
                error={
                  formik.touched.nom_technicien && Boolean(formik.errors.nom_technicien)
                }
                // helperText={formik.touched.nom_technicien && formik.errors.nom_technicien}
              />
            )}
          />
        </Grid> */}

        <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              placeholder="Technicien"
              variant="outlined"
              name="nom_technicien"
              label="Technicien"
              className="my-text-field"
              onChange={formik.handleChange}
              value={formik.values.nom_technicien}
              error={formik.touched.nom_technicien && Boolean(formik.errors.nom_technicien)}
              // helperText={formik.touched.panne && formik.errors.panne}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              placeholder="Compteur"
              variant="outlined"
              name="compteur_depannage"
              label="Compteur"
              className="my-text-field"
              onChange={formik.handleChange}
              value={formik.values.compteur_depannage}
              error={formik.touched.compteur_depannage && Boolean(formik.errors.compteur_depannage)}
              // helperText={formik.touched.panne && formik.errors.panne}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              placeholder="Observation"
              variant="outlined"
              name="observation_depannage"
              label="Observation"
              className="my-text-field"
              onChange={formik.handleChange}
              value={formik.values.observation_depannage}
              error={
                formik.touched.observation_depannage &&
                Boolean(formik.errors.observation_depannage)
              }
              // helperText={formik.touched.observation_depannage && formik.errors.observation_depannage}
            />
          </Grid>
        </Grid>
      )}

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
          variant="contained"
        >
          Valider
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  getAllPersonnel: selectPersonnelItemList,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  createIncidentEnginAsync: (data: any) =>
    dispatch(createIncidentEnginAsync(data)),
  updateIncidentEnginAsync: (data: any) =>
    dispatch(updateIncidentEnginAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreatePanneEnginForm = connector(CreatePanneEnginFormPage);

export default CreatePanneEnginForm;
