import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllNatureTravaux, getAllNatureTravauxAsync } from "@/store/reducers/nature-travaux/natureTravaux.action";


export class NatureTravauxService {

  static getNatureTravaux() {
    axios.get('/nature-travaux').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllNatureTravaux(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createNatureTravaux(data:any) {

    axios.post('/nature-travaux',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllNatureTravauxAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
    
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateNatureTravaux(data:any) {

    axios.put('/nature-travaux',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllNatureTravauxAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteNatureTravaux(data:any) {

    axios.patch('/delete/nature-travaux',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllNatureTravauxAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}