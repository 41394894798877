import profilTypes from "./profil.types";

interface IProfilReducerState {
  list: any,
  itemSelected: any,
  user: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  user: {},
  loading: false,
  error: ""
};

const profilReducer = (
  state: IProfilReducerState = INITIAL_STATE,
  action: any
): IProfilReducerState => {
  switch (action.type) {
    case profilTypes.GET_PROFIL_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case profilTypes.SELECTED_PROFIL:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case profilTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    case profilTypes.GET_PROFIL_USER:
      return {
        ...state,
        user: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default profilReducer;
