import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { Divider, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './Accueil.content.style.scss';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAlerteCountList, selectAlerteItemList } from '@/store/reducers/alerte/alerte.selector';
import { getAllAlertesAsync } from '@/store/reducers/alerte/alerte.action';
import AlertItem from '@/components/Snackbar/AlertItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

const CtAccueilCampaignPage: React.FC<any> = ({alerte, alerteList}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fakeLoading = setTimeout(() => {
        setLoading(false);
    }, 2000);

    return () => {
        clearTimeout(fakeLoading);
    }
  }, []);

  function datesDiff(a:any, b:any) {
    return Math.ceil(((a ? new Date(a) : new Date()).getTime() - (b ? new Date(b) : new Date()).getTime()) / 86400000);
  }

  let chantierList:any[] = [];
  alerteList?.fin_chantier?.forEach((o:any) => {
    chantierList.push({...o, topic: 'chantier', nbreJrs: -datesDiff(new Date(o.date_debut), new Date(o.date_fin))});
  })

  let incidentList:any[] = [];
  alerteList?.incident?.forEach((o:any) => {
    incidentList.push({...o, topic: 'incident'});
  })

  let cuveList:any[] = [];
  alerteList?.cuve?.forEach((o:any) => {
    cuveList.push({...o, topic: 'cuve'});
  })

  let personnelList:any[] = [];
  alerteList?.personnel?.forEach((o:any) => {
    personnelList.push({...o, topic: 'personnel'});
  })

  const list1 = incidentList.concat(chantierList);
  const list2 = list1.concat(cuveList);
  const listItems = list2.concat(personnelList);

  return (
    <div className="ct-accueil-campagne">
      <div className="ct-accueil-campagne__card">
        <div className="title-bloc">
          <span className="title"> Alerte </span>
        </div>
        <Divider className='custom-divider' />
        <PerfectScrollbar id="scrollableDiv" className="content-part">
          <div className="kpi-part">
            {loading ? 
              <>
                <Skeleton variant="rounded" animation="wave" width={200} height={90} />
                <Skeleton variant="rounded" animation="pulse" width={200} height={90} />
                <Skeleton variant="rounded" animation="wave" width={200} height={90} />
              </> 
              : 
              <>
                <div className="kpi-card">
                  <span className="value">
                    {alerte?.cuve + alerte?.fin_chantier + alerte?.incident + alerte?.vidange + alerte?.personnel}
                  </span>
                  <span className="text"> Total alertes </span>
                  <div className="mini-pie">
                    <MiniPiehart
                      value1={15}
                      value2={50}
                      value3={35}
                      value4={0}
                      color1={'#26353F'}
                      color2={'#EF7D00'}
                      color3={'#008F75'}
                      color4={'#ff1744'}
                      height={30}
                      width={30}
                      interRadius={5} />
                  </div>
                </div>
                <div className="kpi-card primary">
                  <span className="percentage"> {((alerte?.fin_chantier * 100) / (alerte?.cuve + alerte?.fin_chantier + alerte?.incident + alerte?.vidange + alerte?.personnel)) | 0}% </span>
                  <span className="value"> {alerte?.fin_chantier || '0'} </span>
                  <span className="text"> Chantier </span>
                </div>
                <div className="kpi-card secondary">
                  <span className="percentage"> {(((alerte?.incident + alerte?.vidange) * 100) / (alerte?.cuve + alerte?.fin_chantier + alerte?.incident + alerte?.vidange + alerte?.personnel)) | 0}% </span>
                  <span className="value"> {(alerte?.incident + alerte?.vidange) || '0'} </span>
                  <span className="text"> Engin </span>
                </div>
                <div className="kpi-card tertiary">
                  <span className="percentage"> {((alerte?.personnel * 100) / (alerte?.cuve + alerte?.fin_chantier + alerte?.incident + alerte?.vidange + alerte?.personnel)) | 0}% </span>
                  <span className="value"> {alerte?.personnel || '0'} </span>
                  <span className="text"> Personne </span>
                </div>
                <div className="kpi-card primary">
                  <span className="percentage"> {((alerte?.cuve * 100) / (alerte?.cuve + alerte?.fin_chantier + alerte?.incident + alerte?.vidange + alerte?.personnel)) | 0}% </span>
                  <span className="value"> {alerte?.cuve || '0'} </span>
                  <span className="text"> Cuve </span>
                </div>
              </>
            }
          </div>
          <>
            {loading && 
              <>
                <Skeleton variant="rounded" animation="wave" width={300} height={120} />
                <Skeleton variant="rounded" animation="wave" width={300} height={120} />
                <Skeleton variant="rounded" animation="wave" width={300} height={120} />
              </>
            }
          </>
          <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllAlertesAsync}
                    hasMore={true}
                    loader={
                      listItems ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems
                              .map((value: any, index:any) => {

                                return <AlertItem 
                                            type={value?.topic === 'chantier' ? 'info': 'error'} 
                                            msg={ value?.topic === 'chantier' ? ('Alerte chantier - '+ value?.nbreJrs + 'Jr(s)') : ('Alerte incident') } 
                                            desc={ value?.topic === 'chantier' ? 
                                            ('Rappel de fin de chantier '+ value?.nom + ' - '+ value?.nbreJrs + 'Jr(s) localise a '+ value?.localisation + 'qui a debute le ' + value?.date_debut + ' finit le '+ value?.date_fin) 
                                            :
                                            
                                            // ('Date incident: ' + value?.date_incient+'Type de panne:' + value?.panne +
                                            // 'Observation sur la panne:' + value?.observation_panne + 'Chef Chantier:' + value?.utilisateur?.prenoms
                                            // + ' Chantier : ' + value?.chantier?.nom + ' N° Parc Engin : ' + value?.engin?.num_parc ) }
                                          (`Date incident: ${new Date(value?.date_incient).toLocaleDateString()} \n Type de panne: ${value?.panne} \n Observation sur la panne: ${value?.observation_panne} \n  Chef Chantier: ${value?.utilisateur?.prenoms} 
                                          \n Chantier : ${value?.chantier?.nom} \n N° Parc Engin : ${value?.engin?.num_parc} `)
                                          }
                                            key={index} />
                            
                                })
                          }
                  </InfiniteScroll>  
        </PerfectScrollbar>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllAlertesAsync: () => dispatch(getAllAlertesAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CtAccueilCampaignPage);
