
import { routePaths } from '@/config';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomePortail.content.styles.scss';

const CtWelcomePortail: React.FC = () => {

  const navigate = useNavigate();

  const goTo = (route: string) => {
    navigate(route, { replace: true });
  }

  return (
    <div className="welcome-portail-container" onClick={()=>{
      goTo(routePaths.adminIVEQIChildren.dashboardAbsolute)
    }}>
        Bienvenue sur IVOIRE EQUIPEMENT
    </div>
  )
}

export default CtWelcomePortail;
