import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectNatureTravaux = (state:TRootState) => state.natureTravaux;

export const selectLoading = createSelector(
  [selectNatureTravaux],
  (natureTravaux:any) => natureTravaux.loading
);

export const selectNatureTravauxItemList = createSelector(
    [selectNatureTravaux],
    (natureTravaux:any) => natureTravaux.list
);

export const selectNatureTravauxDetailLoading = createSelector(
  [selectNatureTravaux],
  (natureTravaux:any) => natureTravaux.loading
);

export const selectNatureTravauxItemSelected = createSelector(
    [selectNatureTravaux],
    (natureTravaux:any) => natureTravaux.itemSelected
);
