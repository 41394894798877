import React from 'react';
import { CtCampaignRoutes } from './routes/campaign.content.routes';

const CtCampaign: React.FC = () => {

  return (
    <>
      <CtCampaignRoutes />
    </>
  );
};

export default CtCampaign;
