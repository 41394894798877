import { AuthService } from "@/services/auth.service";
import authTypes from "./auth.types";

//loading
export const setLoadingAuth = (loading:boolean) => ({
  type: authTypes.AUTH_LOADING,
  payload: loading
});


//store user detail
export const getUser = (user:any) => ({
  type: authTypes.GET_USER,
  payload: user,
});

//store token
export const getUserToken = (token:any) => ({
  type: authTypes.GET_USER_TOKEN,
  payload: token,
});

//verify if otp code has been sent 
export const getOtpCode = (isOtp:boolean) => ({
  type: authTypes.SEND_OPT_CODE,
  payload: isOtp,
});

//catch otp data
export const catchOtpData = (data:any) => ({
  type: authTypes.CATCH_OTP_DATA,
  payload: data,
});



//logout
export const logout = () => ({
  type: authTypes.LOGOUT,
});

export const  loginAsync =  (data:any) =>
(dispatch:any) => {
  console.log("data for login",data);
  
  dispatch(setLoadingAuth(true));
  AuthService.authenticate(data);
};

export const  passwordForgottenAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoadingAuth(true));
  AuthService.passwordForgotten(data);
};

export const  updatePasswordAsync =  (data:any) =>
  (dispatch:any) => {
    dispatch(setLoadingAuth(true));
    AuthService.updatePassword(data);
  };

export const  resetPasswordAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoadingAuth(true));
  AuthService.resetPassword(data);
};

export const  logoutAsync =  () =>
(dispatch:any) => {
  console.log('logout');
  dispatch(logout());
};