import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import absenceReducer from "./absence/absence.reducer";
import authReducer from "./auth/auth.reducer";
import authTypes from "./auth/auth.types";
import categorieEnginReducer from "./categorie-engin/categorieEngin.reducer";
import categorieEquipementReducer from "./categorie-equipement/categorieEquipement.reducer";
import chantierReducer from "./chantier/chantier.reducer";
import clientReducer from "./client/client.reducer";
import congesReducer from "./conges/conges.reducer";
import enginReducer from "./engin/engin.reducer";
import equipementReducer from "./equipement/equipement.reducer";
import natureTravauxReducer from "./nature-travaux/natureTravaux.reducer";
import personnelReducer from "./personnel/personnel.reducer";
import profilReducer from "./profil/profil.reducer";
import sidebarReducer from "./sidebar/sidebar.reducer";
import tableDonneeReducer from "./table-donnee/tableDonnee.reducer";
import typeActiviteReducer from "./type-activite/typeActivite.reducer";
import uniteFacturationReducer from "./unite-facturation/uniteFacturation.reducer";
import userReducer from "./user/user.reducer";
import carteReducer from "./carte/carte.reducer";
import affectationReducer from "./affectation/affectation.reducer";
import incidentReducer from "./incident/incident.reducer";
import vidangeReducer from "./vidange/vidange.reducer";
import alerteReducer from "./alerte/alerte.reducer";
import dashboardReducer from "./dashboard/dashboard.reducer";
import cuveReducer from "./cuve/cuve.reducer";


export const persistConfig = {
  key: "iveqi_v2",
  storage,
  whitelist: ["auth"],
  blacklist: [],
};

const topReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  user : userReducer,
  alerte : alerteReducer,
  profil : profilReducer,
  carte: carteReducer,
  chantier: chantierReducer,
  client: clientReducer,
  engin: enginReducer,
  personnel: personnelReducer,
  conges: congesReducer,
  absence: absenceReducer,
  equipement: equipementReducer,
  natureTravaux: natureTravauxReducer,
  typeActivite: typeActiviteReducer,
  categorieEquipement: categorieEquipementReducer,
  categorieEngin: categorieEnginReducer,
  uniteFacturation: uniteFacturationReducer,
  tableDonnee: tableDonneeReducer,
  affectation: affectationReducer,
  incident: incidentReducer,
  vidange : vidangeReducer,
  dashboard: dashboardReducer,
  cuve: cuveReducer,
});

const rootReducer = (state: any, action: any) => {
  // when RESET action is dispatched it will reset redux state
  if (action.type === authTypes.LOGOUT) {
    state = undefined;
  }
  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
