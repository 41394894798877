import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectEngin = (state:TRootState) => state.engin;

export const selectLoading = createSelector(
  [selectEngin],
  (engin:any) => engin.loading
);

export const selectEnginItemList = createSelector(
    [selectEngin],
    (engin:any) => engin.list
);

export const selectUtilisationByEngin = createSelector(
  [selectEngin],
  (engin:any) => engin.utilisationList
);

export const selectEnginDetailLoading = createSelector(
  [selectEngin],
  (engin:any) => engin.loading
);

export const selectEnginItemSelected = createSelector(
    [selectEngin],
    (engin:any) => engin.itemSelected
);

export const selectIncidentItemList = createSelector(
  [selectEngin],
  (engin:any) => engin.listIncident
);

export const selectVidangeItemSelected = createSelector(
  [selectEngin],
  (engin:any) => engin.itemSelected
);

export const selectSyntheseEnginList = createSelector(
  [selectEngin],
  (engin:any) => engin.getSyntheseList
);