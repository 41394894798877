import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectProfil = (state:TRootState) => state.profil;

export const selectLoading = createSelector(
  [selectProfil],
  (profil:any) => profil.loading
);

export const selectProfilItemList = createSelector(
    [selectProfil],
    (profil:any) => profil.list
);

export const selectProfilDetailLoading = createSelector(
  [selectProfil],
  (profil:any) => profil.loading
);

export const selectProfilItemSelected = createSelector(
    [selectProfil],
    (profil:any) => profil.itemSelected
);

export const selectUserProfil = createSelector(
  [selectProfil],
  (profil:any) => profil.user
);
