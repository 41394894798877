import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectPersonnel = (state:TRootState) => state.personnel;

export const selectLoading = createSelector(
  [selectPersonnel],
  (personnel:any) => personnel.loading
);

export const selectPersonnelItemList = createSelector(
    [selectPersonnel],
    (personnel:any) => personnel.list
);

export const selectAbsenceByPerson = createSelector(
  [selectPersonnel],
  (personnel:any) => personnel.absenceByPerson
);

export const selectPersonnelDetailLoading = createSelector(
  [selectPersonnel],
  (personnel:any) => personnel.loading
);

export const selectPersonnelItemSelected = createSelector(
    [selectPersonnel],
    (personnel:any) => personnel.itemSelected
);

export const selectSynthesePersonnelList = createSelector(
  [selectPersonnel],
  (personnel:any) => personnel.getSyntheseList
);


export const selectHistoriquePersonnelList = createSelector(
  [selectPersonnel],
  (personnel:any) => personnel.getHistoriqueList
);