import { selectAuthToken, selectAuthUser } from "@/store/reducers/auth/auth.selector";
import { setAuthToken } from "@/utils/setAuthToken";
import { connect } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { privateRoutes } from "./private.routes";
import { publicRoutes } from "./public.routes";
import { routePaths } from "../config";
import { useEffect } from "react";
import { getAllChantiersAsync } from "@/store/reducers/chantier/chantier.action";

const AppRoutes:React.FC<any> = ({ token, user, getChantiersAsync }) => {
  console.log("Auth token", token);
  const auth: boolean = !!token;
  setAuthToken(token); 

  const commonRoutes:any = auth === true ? [{ path: "*", element: <Navigate to={routePaths.adminIVEQI} /> }] : [{ path: "*", element: <Navigate to={routePaths.auth} /> }];

  const routes:any = auth === true ? privateRoutes : publicRoutes;
  const element =useRoutes([...routes, ...commonRoutes]);


  useEffect(() => {

    if (auth) {
      
      const url:any = getChantiersAsync(user?.user?.id);  

      const fetchData = async () => {
          try {
              await fetch(url);
          } catch (error) {
              console.log("error", error);
          }
      };
      fetchData();
    }
    
  }, [getChantiersAsync, user, auth]);

  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  token: selectAuthToken,
  user: selectAuthUser
});

const mapDispatchToProps = (dispatch:any) => ({
  getChantiersAsync: (id:any) => dispatch(getAllChantiersAsync(id)),
});


const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AppRoutes);


