import equipementTypes from "./equipement.types";

interface IEquipementReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const equipementReducer = (
  state: IEquipementReducerState = INITIAL_STATE,
  action: any
): IEquipementReducerState => {
  switch (action.type) {
    case equipementTypes.GET_EQUIPEMENT_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case equipementTypes.SELECTED_EQUIPEMENT:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case equipementTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default equipementReducer;
