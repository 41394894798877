import { Navigate, RouteObject } from "react-router-dom";
import { routePaths } from "../config";
import { AuthRoutes } from "@/features/auth/routes/auth.routes";

export const publicRoutes: RouteObject[] = [
  {
    path: routePaths.auth+"/*",
    element: <AuthRoutes/>,
  },
  { path: "*", element: <Navigate to={`${routePaths.auth}`} /> }
];
