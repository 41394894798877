export enum authTypes {
    AUTH_LOADING = "AUTH_LOADING",
    AUTH_ERRORS = "AUTH_ERRORS",
    AUTHENTICATE = "AUTHENTICATE",
    GET_USER = "GET_USER",
    GET_PASSWORD = "GET_PASSWORD",
    GET_USER_TOKEN = "GET_USER_TOKEN",
    REMEMBER_ME_AUTH = "REMEMBER_ME_AUTH",
    LOGOUT = "LOGOUT",
    SEND_OPT_CODE = "SEND_OPT_CODE",
    CATCH_OTP_DATA = "CATCH_OTP_DATA",
  }
  
export default authTypes;
  