import moment from 'moment';
import { writeFile, utils } from 'xlsx';

export function exportToExcel1(listItems: any, columns: any, name: string) {
    // Créer un tableau de données à partir de listItems et columns
    const data = listItems.map((item: any) => {
      const rowData : any = [];
      columns.forEach((col: any) => {
        rowData.push(item[col.field]);
      });
      return rowData;
    });
  
    // Créer un classeur Excel
    const ws = utils.aoa_to_sheet([columns.map((col: any) => col.headerName), ...data]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, name);
  
    // Télécharger le fichier Excel
    writeFile(wb, name+'.xlsx');
}

export function exportToExcel(listItems: any, columns: any, name: string) {
    // Créer un tableau de données à partir de listItems et columns
    const data = listItems.map((item: any) => {
        const rowData: any = [];
        columns.forEach((col: any) => {
            if (col.format === 'date') {
                rowData.push(moment(item[col.field]).format('DD-MM-YYYY'));
            } else {
                rowData.push(item[col.field]);
            }
        });
        return rowData;
    });

    // Créer une feuille de calcul Excel avec en-têtes
    const ws = utils.aoa_to_sheet([columns.map((col: any) => col.headerName), ...data]);

    // Appliquer le format des colonnes
    columns.forEach((col: any, index: number) => {
        if (col.format === 'number') {
            const colLetter = utils.encode_col(index); // Obtenir la lettre de la colonne (A, B, C, etc.)
            for (let row = 1; row <= listItems.length; row++) { // Commencer à 1 pour ignorer l'en-tête
                const cellAddress = `${colLetter}${row + 1}`; // +1 pour ignorer l'en-tête
                if (!ws[cellAddress]) continue; // Si la cellule n'existe pas, continuer
                ws[cellAddress].t = 'n'; // Définir le type de la cellule en tant que nombre
                ws[cellAddress].z = '0'; // Définir le format de la cellule (par exemple, 0 pour des nombres entiers)
            }
        }
    });

    // Créer un classeur Excel
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, name);

    // Télécharger le fichier Excel
    writeFile(wb, name + '.xlsx');
}

