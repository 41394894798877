import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllEngins, getUtilisationByEngin, getAllIncidents, getAllEnginsAsync } from "@/store/reducers/engin/engin.action";


export class EnginService {

  static getEngins() {
    axios.get(`/engin/terrain?chantierId=${localStorage.getItem("chantierId")}`).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllEngins(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getEnginsIncident() {
    axios.get(`/incident/terrain?chantierId=${localStorage.getItem("chantierId")}`).then((response:any)=>{
        console.log(response);
        if(response.error){
            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllIncidents(response.data));
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getUtilisationByEngin(id:number) {
    axios.get(`/utilisation-engin/terrain?chantierEnginId=${localStorage.getItem("chantierId")}&enginId=${id}`).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getUtilisationByEngin(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createUtilisationEngin(data:any) {

    axios.post('/utilisation-engin/terrain',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();
           // ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateUtilisationEngin(data:any) {

    axios.post('/utilisation-engin/update',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createIncidentEngin(data:any) {

    axios.post('/incident-creer/terrain',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }
  
  static updateIncidentEngin(data:any) {

    axios.put('/incident-depannage/terrain',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }


  static updateIncidentEngin2(data:any) {

    axios.put('/incident-modif/terrain',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            // store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }


  static createNoWorkEngin(data:any) {

    axios.post('/noactivite-engin/terrain',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}