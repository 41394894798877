import { NatureTravauxService } from "../../../services/natureTravaux.service";
import { natureTravauxTypes } from "./natureTravaux.types";

export const setLoading = (loading:any) => ({
    type: natureTravauxTypes.LOADING,
    payload: loading,
});

export const getAllNatureTravaux = (list:any) => ({
    type: natureTravauxTypes.GET_NATURE_TRAVAUX_LIST,
    payload: list,
});

export const selectedItem = (natureTravaux:any) => ({
  type: natureTravauxTypes.SELECTED_NATURE_TRAVAUX,
  payload: natureTravaux,
});

 
export const  getAllNatureTravauxAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  NatureTravauxService.getNatureTravaux();
};

export const  createNatureTravauxAsync =  (natureTravaux:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  NatureTravauxService.createNatureTravaux(natureTravaux);
};

export const  updateNatureTravauxAsync =  (natureTravaux:any) =>
(dispatch:any) => {
  console.log("natureTravaux to service", natureTravaux);
  dispatch(setLoading(true));
  NatureTravauxService.updateNatureTravaux(natureTravaux);
};

export const  deleteNatureTravauxAsync =  (data:any) =>
(dispatch:any) => {
  console.log("natureTravaux to service", data);
  dispatch(setLoading(true));
  NatureTravauxService.deleteNatureTravaux(data);
};



