import typeActiviteTypes from "./typeActivite.types";

interface ITypeActiviteReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const typeActiviteReducer = (
  state: ITypeActiviteReducerState = INITIAL_STATE,
  action: any
): ITypeActiviteReducerState => {
  switch (action.type) {
    case typeActiviteTypes.GET_TYPE_ACTIVITE_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case typeActiviteTypes.SELECTED_TYPE_ACTIVITE:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case typeActiviteTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default typeActiviteReducer;
