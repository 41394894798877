import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectUser = (state:TRootState) => state.user;

export const selectLoading = createSelector(
  [selectUser],
  (user:any) => user.loading
);

export const selectUserItemList = createSelector(
    [selectUser],
    (user:any) => user.list
);

export const selectUserDetailLoading = createSelector(
  [selectUser],
  (user:any) => user.loading
);

export const selectUserItemSelected = createSelector(
    [selectUser],
    (user:any) => user.itemSelected
);
