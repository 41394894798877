import { TableDonneeService } from "../../../services/tableDonnee.service";
import { tableDonneeTypes } from "./tableDonnee.types";

export const setLoading = (loading:any) => ({
    type: tableDonneeTypes.LOADING,
    payload: loading,
});

export const getAllTableDonnees = (list:any) => ({
    type: tableDonneeTypes.GET_TABLE_DONNEE_LIST,
    payload: list,
});

export const selectedItem = (tableDonnee:any) => ({
  type: tableDonneeTypes.SELECTED_TABLE_DONNEE,
  payload: tableDonnee,
});

 
export const  getAllTableDonneesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  TableDonneeService.getTableDonnees();
};

export const  createTableDonneeAsync =  (tableDonnee:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  TableDonneeService.createTableDonnee(tableDonnee);
};

export const  updateTableDonneeAsync =  (tableDonnee:any) =>
(dispatch:any) => {
  console.log("tableDonnee to service", tableDonnee);
  dispatch(setLoading(true));
  TableDonneeService.updateTableDonnee(tableDonnee);
};

export const  deleteTableDonneeAsync =  (tableDonnee:any) =>
(dispatch:any) => {
  console.log("tableDonnee to service", tableDonnee);
  dispatch(setLoading(true));
  TableDonneeService.deleteTableDonnee(tableDonnee);
}; 



