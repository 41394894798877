import React from 'react';
import CtAccueilCampaign from '../pages-content/Accueil-content/Accueil.content';

const Accueil:React.FC = () => {
  return (
    <>
      <CtAccueilCampaign />
    </>
  )
}

export default Accueil;