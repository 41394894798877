import React from 'react';
import CtCampaign from '../pages-content/Campaign-content/Campaign.content';

const Campaign:React.FC = () => {
  return (
    <>
      <CtCampaign />
    </>
  )
}

export default Campaign;