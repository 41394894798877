import React, { useState } from "react";
import { Button, Chip, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./Cuve2.content.style.scss";
import { Edit } from "@mui/icons-material";
import { RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material'; // Importer les icônes nécessaires

import { selectCuveItemList } from "@/store/reducers/cuve/cuve.selector";
import SideModal from "../../components/SideModal/SideModal";
import CreateCuveForm from "../../Forms/CreateCuveForm/CreateCuveForm";
import { List, Modal } from "antd";
import { InlineIcon } from "@iconify/react";
//import GaugeDashboard from './GaugeDashboard';

import { selectChantierItemSelected, selectLoading } from "@/store/reducers/chantier/chantier.selector";
import GaugeDashboard from "../../components/GaugeDashboard/GaugeDashboard";

const CtCuveCampaignPage: React.FC<any> = ({ getCuve }) => {
  console.log("getCuve list", getCuve);

  const [openHistorique, setOpenHistorique] = useState<boolean>(false);
  const [type, setType] = useState('retrait');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isAdd, setIsAdd] = useState(false);

  const listItems: any[] = [];
  getCuve.map((x: any) => {
    listItems.push({
      ...x,
      libelle: x?.libelle,
      type: x?.typeCuve,
      quantite: x?.quantite,
      capacite: x?.capacite,
      searchText: x?.libelle + " " + x?.quantite + " " + x?.typeCuve + " " + x?.capacite,
    });
  });

  return (
    <>
      <div className="ct-cuve-campagne">
        <div className="ct-cuve-campagne__card">
          {/* <div className="header-part">
            <div className="title-part">
              <h3>{listItems[0]?.libelle?.toUpperCase()}</h3>
              <div className="title-info">
                <Chip
                  label={`Capacite totale: ${listItems[0]?.capacite}`}
                  className="chip"
                />
                <Chip
                  label={`Quantite disponible: ${listItems[0]?.quantite}`}
                  className="chip"
                />
              </div>
            </div>
            <Divider className="custom-divider" />
          </div> */}

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <GaugeDashboard
              capacite={listItems[0]?.capacite || 1}
              quantite={listItems[0]?.quantite || 0}
              nom_chantier = {listItems[0]?.libelle?.toUpperCase()}
            />
            <div className="content-actions">
              <Button
                color="blue"
                className="btn-option"
                onClick={() => {
                  handleOpen();
                  setIsAdd(true);
                  setType('approvisionnement');
                }}
                startIcon={<Edit />}
              >
                Approvisionner
              </Button>

              <Divider orientation="vertical" />

              <Button
                color="blue"
                className="btn-option"
                onClick={() => {
                  handleOpen();
                  setIsAdd(true);
                  setType('retrait');
                }}
                startIcon={<InlineIcon icon="carbon:document-import" />}
              >
                Retirer
              </Button>

              <Divider orientation="vertical" />
              <Button
                color="blue"
                className="btn-option"
                onClick={() => {
                  setOpenHistorique(true);
                }}
                startIcon={<InlineIcon icon="carbon:document-import" />}
              >
                Historique
              </Button>
            </div>
          </PerfectScrollbar>
        </div>
      </div>

      <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
        {isAdd && (
          <CreateCuveForm closeModal={handleClose} cuveData={listItems} type={type} />
        )}
      </SideModal>

      <Modal
        title={<p>Historique ({listItems[0]?.utilisation_cuves?.length || "N/A"})</p>}
        footer={<></>}
        open={openHistorique}
        onCancel={() => setOpenHistorique(false)}
      >
        <List
          itemLayout="horizontal"
          style={{ height: "400px", overflow: "auto" }}
          dataSource={listItems[0]?.utilisation_cuves}
          renderItem={(item: any, index: any) => (
            <List.Item key={index}>
              <List.Item.Meta
                title={`${item.date} | ${item.type}`}
                description={`${item.quantite} L transferee(s). La nouvelle quantite est: ${item.quantite_cuve_apres} L`}
               // avatar={<Edit />}
               avatar={item.type === 'Retrait' ? <RemoveCircleOutline /> : <AddCircleOutline />} // Condition pour afficher l'icône en fonction du type


              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

const mapStateToProps: any = createStructuredSelector({
  loading: selectLoading,
  getCuve: selectCuveItemList,
  chantierSelected: selectChantierItemSelected,
});

const connector = connect(mapStateToProps);
const CtCuve2Campaign = connector(CtCuveCampaignPage);

export default CtCuve2Campaign;
