import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectCuve = (state:TRootState) => state.cuve;

export const selectLoading = createSelector(
  [selectCuve],
  (cuve:any) => cuve.loading
);

export const selectCuveItemList = createSelector(
    [selectCuve],
    (cuve:any) => cuve.list
);

export const selectCuveItemDetail = createSelector(
  [selectCuve],
  (cuve:any) => cuve.detail
);

export const selectCuveDetailLoading = createSelector(
  [selectCuve],
  (cuve:any) => cuve.loading
);

export const selectCuveItemSelected = createSelector(
    [selectCuve],
    (cuve:any) => cuve.itemSelected
);


