
import { SuspenseFallback } from '@/components/SuspenseFallback/SuspenseFallback';
import { selectOpened } from '@/store/reducers/sidebar/sidebar.selector';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet} from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import HeaderCIE from '../containers/Header/Header.container';
import './AdminIVEQI.layout.styles.scss';

const LAdminIVEQIFunction: React.FC = () => {

  useEffect(() => {
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="l-admin-cie">
      <div className="l-admin-cie__header-part">
          <HeaderCIE/>
      </div>
      <div className="l-admin-cie__content-part">
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
});


const connector = connect(mapStateToProps);
const LAdminIVEQI = connector(LAdminIVEQIFunction);

export default LAdminIVEQI;
