import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllTableDonnees, getAllTableDonneesAsync } from "@/store/reducers/table-donnee/tableDonnee.action";


export class TableDonneeService {

  static getTableDonnees() {
    axios.get('/table-donnee').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllTableDonnees(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createTableDonnee(data:any) {

    axios.post('/table-donnee',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllTableDonneesAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateTableDonnee(data:any) {

    axios.put('/table-donnee',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllTableDonneesAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteTableDonnee(data:any) {

    axios.patch('/delete/table-donnee',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllTableDonneesAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
    
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}