import { InlineIcon } from "@iconify/react";
import {
  Edit,
  ListAlt,
  TuneRounded,
  TurnedIn,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import SideModal from "../../components/SideModal/SideModal";
import "./Personnel.content.style.scss";
import MiniPiehart from "@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { connect } from "react-redux";
import {
  getAbsenceByPersonAsync,
  getAllPersonnelsAsync,
} from "@/store/reducers/personnel/personnel.action";
import {
  selectPersonnelItemList,
  selectLoading,
  selectAbsenceByPerson,
  selectHistoriquePersonnelList,
} from "@/store/reducers/personnel/personnel.selector";
import { createStructuredSelector } from "reselect";
import ListeItemCardGenerique from "@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique";
import { Stack } from "@mui/material";
import { selectChantierItemSelected } from "@/store/reducers/chantier/chantier.selector";
import { List, Modal } from "antd";
import _ from "lodash";
import CreateAbsencePersonnelForm from "../../Forms/CreateAbsencePersonnelForm/CreateAbsencePersonnelForm";

const columns = [
  {
    field: "statut",
    headerName: "Statut",
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            backgroundColor: (params?.row?.statut === -1) ? 'red': 'green',
            width: "90%",
            height: "15px",
            borderRadius: "6px"
          }}
        >
          {(params?.row?.statut === -1) ? <span>Absent</span> : <span>Present</span>}
        </Box>
      );
    }
  },
  {
    field: "matricule",
    headerName: "Matricule",
    width: 100,
  },
  {
    field: "nom",
    headerName: "Nom",
    width: 120,
  },
  {
    field: "prenoms",
    headerName: "Prénoms",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },
  {
    field: "fonction",
    headerName: "Fonction",
    width: 200,
  },
  {
    field: "telephone",
    headerName: "Telephone",
    width: 150,
  },
  {
    field: "date_chantier",
    headerName: "Date d'affectation",
    width: 150,
  },
 
  
];



const CtPersonnelRhPage: React.FC<any> = ({
  loading,
  personnelList,
  absenceByPerson,
  getAbsenceByPersonAsync,
  allAbsentList,
  chantierSelected,
}) => {

  const [typeOperation, setTypeOperation] = useState("Ajout");
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const [isCardMode, setIsCardMode] = useState(true);

  const [selection, setSelection] = React.useState<any>(null);
  const [openHistorique, setOpenHistorique] = useState<boolean>(false);

  let listItems: any[] = [];
  const dataItems: any[] = [];
  personnelList.forEach((x: any) => {
    dataItems.push({
      ...x,
      ...x.personnel,
      chantier: x,
      statut: x.personnel.statut_chantier,
      // situation: (x.chantierId) ? x.chantier?.nom +' à '+x.chantier?.localisation : 'Pas sur chantier',
      searchText:
        x.personnel.matricule +
        " " +
        x.personnel.nom +
        " " +
        x.personnel.prenoms +
        " " +
        x.personnel.email +
        " " +
        x.personnel.fonction,
    });
  });

  listItems = _.uniqBy(dataItems, 'id');

  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (
        value.searchText
          .toLocaleLowerCase()
          .indexOf(searchText.toLocaleLowerCase() || "") !== -1
      );
    });
  };

  // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
  //  const handleExportExcel = () => {
  //   exportToExcel(listItems, columns, 'personnel');
  // };

  const selectItemFunc = (idElt: number | any) => {
    const element: any = getListItemsFilteredSearch().find(
      (x: any) => x.id === idElt
    );
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }
    setSelection(element);
  };

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard;
  };

  return (
    <>
      <div className="ct-personnels-campagne">
        <div className="ct-personnels-campagne__card">
          <div className="header-part">
            <div className="title-part">
              <div className="title-bloc">
                <span className="title"> Gestion du personnel </span>
                <Chip
                  label={` ${listItems?.length} ${
                    listItems?.length < 2 ? "personne" : "personnes"
                  } `}
                  className="total-number-chip"
                />

                <Badge color="secondary">
                  <IconButton
                    className="close-icon"
                    size="small"
                    color="blue"
                    onClick={() => {
                      handleOpen();
                      setIsAdd(false);
                    }}
                  >
                    <TuneRounded />
                  </IconButton>
                </Badge>

                <Badge
                  color="secondary"
                  variant="dot"
                  //  invisible={ valueFromSelect?.length === 0}
                >
                  <IconButton
                    className="close-icon"
                    title={`Mode ${isCardMode ? "liste" : "carte"}`}
                    size="small"
                    color="blue"
                    onClick={() => {
                      setIsCardMode(!isCardMode);
                    }}
                  >
                    {isCardMode ? <ListAlt /> : <TurnedIn />}
                  </IconButton>
                </Badge>
              </div>
              <div className="toolbar-part">
                <div className="actions-part">
                  {selection ? (
                    <>
                      <Divider orientation="vertical" />
                      <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          handleOpen();
                          setIsAdd(true);
                          setTypeOperation("Déclarer une absence");
                        }}
                        startIcon={<Edit />}
                      >
                        Declarer une absence
                      </Button>

                      <Divider orientation="vertical" />
                      <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setOpenHistorique(true);
                          getAbsenceByPersonAsync(selection?.id);
                        }}
                        //variant='contained'
                        startIcon={<InlineIcon icon="carbon:document-import" />}
                      >
                        Historique
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* <Divider orientation="vertical" />
                      <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setOpenHistorique(true);
                        }}
                        startIcon={<ListAlt />}
                      >
                        Liste des absents
                      </Button> */}
                    </>
                  )}
                </div>

                <TextField
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className="search-input"
                  placeholder="Recherche ... "
                  variant="outlined"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InlineIcon icon="bi:search" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <Divider className="custom-divider" />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames("kpi-card")}>
                <span className="value">{listItems?.length}</span>
                <span className="text">Total</span>
                <div className="mini-pie">
                  <MiniPiehart
                    value1={15}
                    value2={50}
                    value3={35}
                    value4={0}
                    color1={"#EF7D00"}
                    color2={"#26353F"}
                    color3={"#008F75"}
                    color4={"#ff1744"}
                    height={30}
                    width={30}
                    interRadius={5}
                  />
                </div>
              </div>
            </div>
            <>
              {loading && (
                <>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={300}
                    height={120}
                  />
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    width={300}
                    height={120}
                  />
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={300}
                    height={120}
                  />
                </>
              )}
              {isCardMode ? (
                <InfiniteScroll
                  className="content-part__infinite-scroll-part"
                  scrollableTarget="scrollableDiv"
                  dataLength={listItems?.length}
                  next={getAllPersonnelsAsync}
                  hasMore={true}
                  loader={
                    listItems ? (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={300}
                        height={120}
                      />
                    ) : (
                      <p> Aucune donnée disponible </p>
                    )
                  }
                >
                  {!loading &&
                    listItems
                      .filter((value: any) => {
                        return (
                          value.searchText
                            .toLocaleLowerCase()
                            .indexOf(searchText.toLocaleLowerCase() || "") !==
                          -1
                        );
                      })
                      .map((value: any, index: any) => {
                        return (
                          <ListeItemCardGenerique
                            key={index}
                            id={value.id}
                            setOpen={setOpen}
                            columns={columns}
                            data={[value]}
                            setIsEdit={selectItemFunc}
                            isActive={isCardActive(value.id)}
                          />
                        );
                      })}
                </InfiniteScroll>
              ) : (
                <div style={{ height: "85%", width: "100%" }}>
                  <DataGrid
                    components={{
                      NoRowsOverlay: () => (
                        <Stack
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          Pas de donnees
                        </Stack>
                      ),
                    }}
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                          `${from} - ${to} Total: ${count}`,
                      },
                    }}
                    rows={getListItemsFilteredSearch()}
                    columns={columns}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 7 } },
                    }}
                    onRowSelectionModelChange={(row) => {
                      selectItemFunc(row[0]);
                    }}
                  />
                </div>
              )}
            </>
          </PerfectScrollbar>
        </div>
      </div>

      <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
        <>
          {isAdd && (
            <CreateAbsencePersonnelForm
              closeModal={handleClose}
              selectedItem={selection}
              typeOperation={typeOperation}
            />
          )}
        </>
      </SideModal>
      {selection?.id ? 
        <Modal
          title={
            <p>
              Historique ({absenceByPerson?.length || "N/A"})
            </p>
          }
          footer={<></>}
          open={openHistorique}
          onCancel={() => setOpenHistorique(false)}
        >
          <List
            itemLayout="horizontal"
            style={{
              height: "400px",
              overflow: "auto",
            }}
            dataSource={absenceByPerson}
            renderItem={(item: any, index: any) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={`${item?.date_depart} | Absence`}
                  description={`L'agent a ete absent pendant ${item?.nombre_jr_absent} jour(s)`}
                  avatar={<Edit onClick={(item:any)=>{setSelection(item); setOpen(true); setTypeOperation("Modifier une absence");}} />}
                />
              </List.Item>
            )}
          />
        </Modal>

        :

        <Modal
          title={
            <p>
              List des absents ({allAbsentList?.length || "N/A"})
            </p>
          }
          footer={<></>}
          open={openHistorique}
          onCancel={() => setOpenHistorique(false)}
        >
          <List
            itemLayout="horizontal"
            style={{
              height: "400px",
              overflow: "auto",
            }}
            dataSource={allAbsentList}
            renderItem={(item: any, index: any) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={`${item?.date_depart} | Absence`}
                  description={`L'agent(e) (${item?.matricule + '-'+item?.nom + ' '+ item?.prenoms}) a ete absent(e) pendant ${item?.nombre_jr_absent} jour(s)`}
                  avatar={<Edit onClick={(item:any)=>{setSelection(item); setOpen(true)}} />}
                />
              </List.Item>
            )}
          />
        </Modal>
      }
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  personnelList: selectPersonnelItemList,
  absenceByPerson: selectAbsenceByPerson,
  allAbsentList: selectHistoriquePersonnelList,
  chantierSelected: selectChantierItemSelected,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAbsenceByPersonAsync: (id: any) => dispatch(getAbsenceByPersonAsync(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CtPersonnelRh = connector(CtPersonnelRhPage);

export default CtPersonnelRh;
