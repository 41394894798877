import vidangeTypes from "./vidange.types";

interface IVidangeReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const vidangeReducer = (
  state: IVidangeReducerState = INITIAL_STATE,
  action: any
): IVidangeReducerState => {
  switch (action.type) {
    case vidangeTypes.GET_VIDANGE_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case vidangeTypes.SELECTED_VIDANGE:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case vidangeTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default vidangeReducer;
