
import React from 'react';
import { routePaths } from '@/config';
import { TextField, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import logoApp from "@/assets/images/png/logo.png";
import './ForgottenPasswordForm.styles.scss';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Icon } from '@iconify/react';

//redux part
import { connect } from 'react-redux';
import { passwordForgottenAsync } from '@/store/reducers/auth/auth.action';
import { selectAuthLoading, selectOtpCodeStatus } from '@/store/reducers/auth/auth.selector';
import { createStructuredSelector } from "reselect";

const ForgottenPasswordFormPage:React.FC<any> = ({forgottenPassAsync, isOtpCodeSent, loading}) =>  {

  const navigate = useNavigate();

  const validationSchema = yup.object({
    login: yup.string().required(" L'email de la liste est obligatoire "),
});

  const formik = useFormik({
      initialValues: {
          login: '',
      },
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values: any) => {
        forgottenPassAsync(values);
        setTimeout(() => {
          if (!loading && isOtpCodeSent) {
            navigate(routePaths.authChildren.resetPasswordAbsolute,{replace:true});  
          } 
        }, 2000);
        
      }
  });

  return (
    <form className="forgotten-password-container" onSubmit={formik.handleSubmit}>
      <div className="header-part">
        <div className='logo-part'>
          <img src={logoApp} alt="" className="logo-app" />
          <Button className='return-button' href={routePaths.authChildren.signInAbsolute}>
            <Icon icon="akar-icons:arrow-left" className='icon-return'/>
            Retour
          </Button>
          {/* <Link className="link-primary"
                to={routePaths.authChildren.signInAbsolute} >
                  <Icon icon="akar-icons:arrow-left" className='icon-return'/>
                  Retour
          </Link> */}
        </div>
        <span className="title">Modifier mot de passe</span>
        {/* <span className="subtitle"> Voulez-vous modifier votre mot de passe ? </span> */}
        <span className='description'>Veuilez saisir l'adresse e-mail rattaché à votre compte pour recevoir un e-mail avec les instructions et changer votre mot de passe</span>
      </div>
      <div className="form-part">
          <TextField  placeholder="Votre addresse email" 
                       name="login"
                       label="Email"
                       className='my-text-field'
                       onChange={formik.handleChange}
                       value={formik.values.login}
                       error={formik.touched.login && Boolean(formik.errors.login)}
                       // helperText={formik.touched.nom && formik.errors.nom}
                       type="text" 
                      />
          <Button variant="contained" 
                  color='primary'
                  sx={{marginTop:'30px'}}
                  type='submit'
                  >ENVOYER LA DEMANDE DE RÉINITIALISATION</Button>
      </div>
      <div className="footer-part">
      </div>
    </form>
  )
}

const mapStateToProps = createStructuredSelector({
  loading: selectAuthLoading,
  isOtpCodeSent: selectOtpCodeStatus,
});

const mapDispatchToProps = (dispatch:any) => ({
  forgottenPassAsync: (data:any) => dispatch(passwordForgottenAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const ForgottenPasswordForm = connector(ForgottenPasswordFormPage);

export default ForgottenPasswordForm;

