import React from 'react';
import SigninForm from '../page-contents/SigninForm/SigninForm';

const Signin: React.FC = () => {
  return (
    <>
      <SigninForm />
    </>
  )
}

export default Signin;
