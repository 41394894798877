import tableDonneeTypes from "./tableDonnee.types";

interface ITableDonneeReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const tableDonneeReducer = (
  state: ITableDonneeReducerState = INITIAL_STATE,
  action: any
): ITableDonneeReducerState => {
  switch (action.type) {
    case tableDonneeTypes.GET_TABLE_DONNEE_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case tableDonneeTypes.SELECTED_TABLE_DONNEE:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case tableDonneeTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default tableDonneeReducer;
