export enum profilTypes {
    LOADING = "LOADING",
    CREATE_PROFIL = "CREATE_PROFIL",
    UPDATE_PROFIL = "UPDATE_PROFIL",
    REMOVE_PROFIL = "REMOVE_PROFIL",
    SELECTED_PROFIL = "SELECTED_PROFIL",
    GET_PROFIL_LIST = "GET_PROFIL_LIST",
    GET_PROFIL_USER = "GET_PROFIL_USER",
}
  
export default profilTypes;