export enum affectationTypes {
    LOADING = "LOADING",
    SELECTED_AFFECTED_PERSON_BY_CHANTIER = "SELECTED_AFFECTED_PERSON_BY_CHANTIER",
    GET_AFFECTED_PERSON_BY_CHANTIER_LIST = "GET_AFFECTED_PERSON_BY_CHANTIER_LIST",
    SELECTED_AFFECTED_ENGIN_BY_CHANTIER = "SELECTED_AFFECTED_ENGIN_BY_CHANTIER",
    GET_AFFECTED_ENGIN_BY_CHANTIER_LIST = "GET_AFFECTED_ENGIN_BY_CHANTIER_LIST",
    GET_DETAIL_ABOUT_ENGIN_AFFECTED = "GET_DETAIL_ABOUT_ENGIN_AFFECTED",
    GET_DETAIL_ABOUT_RH_AFFECTED = "GET_DETAIL_ABOUT_RH_AFFECTED",
}
  
export default affectationTypes;