import userTypes from "./user.types";

interface IUserReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const userReducer = (
  state: IUserReducerState = INITIAL_STATE,
  action: any
): IUserReducerState => {
  switch (action.type) {
    case userTypes.GET_USER_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case userTypes.SELECTED_USER:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case userTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default userReducer;
