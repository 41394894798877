import affectationTypes from "./affectation.types";

interface IAffectationReducerState {
  affectedPersonByChantierList: any,
  affectedPersonByChantierItemSelected: any,
  affectedEnginByChantierList: any,
  affectedEnginByChantierItemSelected: any,
  affectedEnginDetail: any,
  affectedRhDetail: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  affectedPersonByChantierList: [],
  affectedPersonByChantierItemSelected: {},
  affectedEnginByChantierList: [],
  affectedEnginByChantierItemSelected: {},
  affectedEnginDetail: {},
  affectedRhDetail: {},
  loading: false,
  error: ""
};

const affectationReducer = (
  state: IAffectationReducerState = INITIAL_STATE,
  action: any
): IAffectationReducerState => {
  switch (action.type) {
    case affectationTypes.GET_AFFECTED_PERSON_BY_CHANTIER_LIST:
      return {
        ...state,
        affectedPersonByChantierList: action.payload,
        error: ""
      };

    case affectationTypes.SELECTED_AFFECTED_PERSON_BY_CHANTIER:
      return {
        ...state,
        affectedPersonByChantierItemSelected: action.payload,
        error: ""
      };

    case affectationTypes.GET_DETAIL_ABOUT_RH_AFFECTED:
      return {
        ...state,
        affectedRhDetail: action.payload,
        error: ""
      };

    case affectationTypes.GET_AFFECTED_ENGIN_BY_CHANTIER_LIST:
      return {
        ...state,
        affectedEnginByChantierList: action.payload,
        error: ""
      };

    case affectationTypes.SELECTED_AFFECTED_ENGIN_BY_CHANTIER:
      return {
        ...state,
        affectedEnginByChantierItemSelected: action.payload,
        error: ""
      };

    case affectationTypes.GET_DETAIL_ABOUT_ENGIN_AFFECTED:
      return {
        ...state,
        affectedEnginDetail: action.payload,
        error: ""
      };
    
    case affectationTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default affectationReducer;
