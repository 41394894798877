import chantierTypes from "./chantier.types";

interface IChantierReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string,
  getPersonnelList: any[],
  getEnginList: any[],
  getSyntheseList: any,
  getHistoriqueList: any,
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: "",
  getPersonnelList: [],
  getEnginList: [],
  getSyntheseList: {},
  getHistoriqueList: {},
};

const chantierReducer = (
  state: IChantierReducerState = INITIAL_STATE,
  action: any
): IChantierReducerState => {
  switch (action.type) {
    case chantierTypes.GET_CHANTIER_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case chantierTypes.SELECTED_CHANTIER:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case chantierTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    case chantierTypes.GET_PERSONNEL_CHANTIER:
      return {
        ...state,
        getPersonnelList: action.payload,
        error: ""
      };

    case chantierTypes.GET_ENGIN_CHANTIER:
      return {
        ...state,
        getEnginList: action.payload,
        error: ""
      };

    case chantierTypes.GET_SYNTHESE_CHANTIER:
      return {
        ...state,
        getSyntheseList: action.payload,
        error: ""
      };

    case chantierTypes.GET_HISTORIQUE_CHANTIER:
      return {
        ...state,
        getHistoriqueList: action.payload,
        error: ""
      };
      
    default:
      return state;
  }
};

export default chantierReducer;
