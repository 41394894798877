import { routePaths } from "@/config";
import { Navigate, Route, Routes } from "react-router-dom";
import LAdminIVEQI from "../layouts/AdminIVEQI.layout";
// import Administration from "../pages/Administration.page";
import Campaign from "../pages/Campaign.page";
// import Dashboard from "../pages/Dashboard.page";
// import Map from "../pages/Map.page";
// import Rh from "../pages/Rh.page";

export const AdminIVEQIRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LAdminIVEQI />}>
              <Route index element={ <Navigate to={`${routePaths.adminIVEQIChildren.campaignAbsolute}`} /> } />
              {/* <Route path={routePaths.adminIVEQIChildren.dashboard} element={ <Dashboard />} /> */}
              {/* <Route path={routePaths.adminIVEQIChildren.map} element={ <Map />} /> */}
              <Route path={routePaths.adminIVEQIChildren.campaign+"/*"} element={ <Campaign />} />
              {/* <Route path={routePaths.adminIVEQIChildren.rh+"/*"} element={ <Rh />} />
              <Route path={routePaths.adminIVEQIChildren.administration+"/*"} element={ <Administration />} /> */}
              <Route path="/*" element={ <Navigate to={`${routePaths.adminIVEQIChildren.campaignAbsolute}`} />} />
          </Route>
      </Routes>
    );
};