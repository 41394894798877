import {  Box, Grid } from '@mui/material';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './RapportPersonnel.content.style.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import DashboardFilterRh from '@/features/admin-iveqi/components/Dashboard-filter-rh/DashboardFilterRh';
import { selectHistoriquePersonnelList } from '@/store/reducers/personnel/personnel.selector';
import DashboardRhCard from '@/features/admin-iveqi/components/Dashboard-rh-card/DashboardRhCard';


const CtRapportPersonnelPage: React.FC<any> = ({
  historiquePersonnel
}) =>  {

  console.log('historiquePersonnel CtHistoriqueRhPage', historiquePersonnel);
 

  return (
    <>
      <DashboardFilterRh />
      <PerfectScrollbar className="ct-dashboard">
        <Box>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <DashboardRhCard historiqueRhs={historiquePersonnel}  />
              </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  historiquePersonnel: selectHistoriquePersonnelList,
});

const connector = connect(mapStateToProps);
const CtRapportPersonnel = connector(CtRapportPersonnelPage);

export default CtRapportPersonnel;
