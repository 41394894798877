import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectChantier = (state:TRootState) => state.chantier;

export const selectLoading = createSelector(
  [selectChantier],
  (chantier:any) => chantier.loading
);

export const selectChantierItemList = createSelector(
    [selectChantier],
    (chantier:any) => chantier.list
);

export const selectChantierDetailLoading = createSelector(
  [selectChantier],
  (chantier:any) => chantier.loading
);

export const selectChantierItemSelected = createSelector(
    [selectChantier],
    (chantier:any) => chantier.itemSelected
);

export const selectPersonnelChantierList = createSelector(
  [selectChantier],
  (chantier:any) => chantier.getPersonnelList
);

export const selectEnginChantierList = createSelector(
  [selectChantier],
  (chantier:any) => chantier.getEnginList
);

export const selectSyntheseChantierList = createSelector(
  [selectChantier],
  (chantier:any) => chantier.getSyntheseList
);

export const selectHistoriqueChantierList = createSelector(
  [selectChantier],
  (chantier:any) => chantier.getHistoriqueList
);
export const selectHistoriqueChantierListEngin = createSelector(
  [selectChantier],
  (chantier:any) => chantier.getHistoriqueListChantierEngin
);