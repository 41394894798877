import cuveTypes from "./cuve.types";

interface ICuveReducerState {
  list: any,
  detail: any,
  itemSelected: any,
  user: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  detail: {},
  itemSelected: {},
  user: {},
  loading: false,
  error: ""
};

const cuveReducer = (
  state: ICuveReducerState = INITIAL_STATE,
  action: any
): ICuveReducerState => {
  switch (action.type) {
    case cuveTypes.GET_CUVE_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case cuveTypes.GET_CUVE_DETAIL:
      return {
        ...state,
        detail: action.payload,
        error: ""
      };

    case cuveTypes.SELECTED_CUVE:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case cuveTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };


    default:
      return state;
  }
};

export default cuveReducer;
