import React from 'react';
import { Box, Typography, Grid, IconButton, Divider, LinearProgress, styled } from '@mui/material';
import GaugeChart from 'react-gauge-chart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './GaugeDashboard.scss';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';


interface GaugeDashboardProps {
  capacite: number;
  quantite: number;
  nom_chantier: string;
}

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    background: 'linear-gradient(to right, #00ff00, #ffff00, #ff0000)',
  }));
  

const GaugeDashboard: React.FC<GaugeDashboardProps> = ({ capacite, quantite ,nom_chantier }) => {
  const percent = quantite / capacite;

  return (
    <Box sx={{ padding: 1 }}>
     
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Typography variant="h5">Chantier: {nom_chantier} </Typography>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 2,
            background: 'linear-gradient(to right, #00ff00, #ffff00, #ff0000)',
            borderRadius: 10,
            padding: 1,
          }}
        >
          <Typography variant="h6" component="span">
            Restant : {quantite}L
          </Typography>
          <Typography variant="h6" component="span" sx={{ marginLeft: 4 }}>
            Total : {capacite}L
          </Typography>
        </Box> */}

        <Box sx={{ padding: 2, textAlign: 'center' }}>
            <Box sx={{ marginBottom: 2 }}>
                <GradientLinearProgress variant="determinate" value={percent} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Restant : {quantite} L</Typography>
                <Typography>Capacité : {capacite} L</Typography>
            </Box>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <GaugeChart
            id="gauge-chart"
            animate={true} 

            nrOfLevels={30}
            percent={percent}
            colors={['#00ff00', '#ffff00', '#ff0000']}
            arcWidth={0.3}
            textColor="#000000"
            needleColor="#ff0000"
            formatTextValue={(value: any) => `${Math.round(percent * 100)}%`}
          />
          <LocalGasStationIcon
            sx={{
              position: 'absolute',
              top: '68%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 28,
              color: '#000000',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GaugeDashboard;
