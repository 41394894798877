import { InlineIcon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "./CreateAbsencePersonnelForm.style.scss";

import "dayjs/locale/fr";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import {
  createAbsentPersonnelAsync,
  updateAbsentPersonnelAsync,
} from "@/store/reducers/personnel/personnel.action";

const CreateAbsencePersonnelFormPage: React.FC<any> = ({
  selectedItem,
  createAbsentPersonAsync,
  updateAbsentPersonAsync,
  typeOperation,
  closeModal,
  userConnected
}) => {

  const motifList: { id: string, value: string }[]= [
    {id:'Retard', value: "Retard"},
    {id:'Raison personnelle', value: "Raison personnelle"},
    {id: 'Je ne sais pas', value: "Je ne sais pas"},
    {id: 'Maladie (privée)', value: "Maladie (privée)"},
    {id:'Permissionnaire', value: "Permissionnaire"},
    {id: 'Congés', value: "Congés"},
    {id:'Accident (professionnel)', value: "Accident (professionnel)"},
  ];

  const [heureDebut, setHeureDebut] = useState<any>(
    selectedItem ? dayjs(selectedItem.heure_debut) : dayjs(new Date())
  );

  const [heureFin, setHeureFin] = useState<any>(
    selectedItem ? dayjs(selectedItem.heure_fin) : dayjs(new Date())
  );

  const [motif, setMotif] = useState<{ id: string, value: string }>({ id: '', value: '' });
  const handleMotifChange = (event: any, value: { id: string, value: string } | null) => {
      console.log(value);
      if (value == null) {
          setMotif({ id: '', value: '' });
      } else {
          setMotif(value as { id: string, value: string });
      }
      formik.setFieldValue('motif', value?.value);
  };


   // Fonction pour calculer le nombre d'heures d'absence
  //  const calculateAbsenceHours = (start: Dayjs, end: Dayjs) => {
  //   const duration = dayjs.duration(end.diff(start));
  //   const hours = duration.asHours();
  //   formik.setFieldValue("nombre_heure_absence", hours);
  // };

  useEffect(() => {
    const duration = parseFloat(formik.values.heure_fin) - parseFloat(formik.values.heure_debut);
    formik.setFieldValue("nombre_heure_absence", duration);
  }, [heureDebut, heureFin]);



  const validationSchema = yup.object({
    // date: yup.string().required(" Ce champ est obligatoire "),
    // heure_debut: yup.string().required("Ce champ est obligatoire  "),
    // heure_fin: yup.string().required("Ce champ est obligatoire  "),
    // motif: yup.string().required("Ce champ est obligatoire  "),
  });

  const formik = useFormik({
    initialValues: {
      date: new Date(),
      heure_debut: `${heureDebut?.$H}:${heureDebut?.$m}`,
      heure_fin: `${heureFin?.$H}:${heureFin?.$m}`,
      nombre_heure_absence: 0,
      motif: "",
      observation: "",
      utilisateurId: userConnected?.user?.id,
      chantierPersonnelId: selectedItem ? selectedItem?.chantier?.id : null,
      chantierId: localStorage.getItem("chantierId"),
      personnelId: selectedItem ? selectedItem.id : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {

      console.log("values personnel absence", values);

      console.log("values date personnel", JSON.stringify(values));


      // values.nombre_heure_absence = Math.abs(values.heure_fin - values.heure_debut) / 36e5;

      if (typeOperation === "Déclarer une absence") {
        createAbsentPersonAsync(values);
      } else {
        updateAbsentPersonAsync(values);
      }
      closeModal();
    },
  });

  return (
    <form
      encType="multipart/form-data"
      className="create-list-form"
      onSubmit={formik.handleSubmit}
    >
      <span className="title">
        {typeOperation === "Déclarer une absence"
          ? "Déclarer une absence"
          : "Modifier une absence"}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Nom"
            variant="outlined"
            name="nom"
            label="Nom"
            inputProps={{
              readOnly: Boolean(true),
            }}
            className="my-text-field"
            value={selectedItem?.nom}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Prénoms"
            variant="outlined"
            name="prenoms"
            label="Prénoms"
            className="my-text-field"
            inputProps={{
              readOnly: Boolean(true),
            }}
            value={selectedItem?.prenoms}
            type="text"
          />
        </Grid>       

        <Grid item xs={12} sm={12} lg={12} xl={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <TimePicker
                label="Heure de depart "
                value={heureDebut}
                onChange={(newValue:any) => {
                  setHeureDebut(newValue);
                  formik.setFieldValue(
                    "heure_debut",
                    `${newValue?.$H}:${newValue?.$m}`
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} className="my-text-field" />
                )}
              />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            
              <TimePicker
                label="Heure de retour"
                value={heureFin}
                onChange={(newValue: any) => {
                  setHeureFin(newValue);
                  formik.setFieldValue(
                    "heure_fin",
                    `${newValue?.$H}:${newValue?.$m}`
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} className="my-text-field" />
                )}
              />
            
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Nombre d'heure d'absence"
            variant="outlined"
            name="nombre_heure_absence"
            label="nombre_heure_absence"
            className="my-text-field"
            inputProps={{
              readOnly: Boolean(true),
            }}
            value={formik.values.nombre_heure_absence || 0}
            error={
              formik.touched.nombre_heure_absence &&
              Boolean(formik.errors.nombre_heure_absence)
            }
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12} >
            <Autocomplete
                id="id-service-select"
                options={motifList}
                value={motif}
                onChange={handleMotifChange}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.value}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.value}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className='my-text-field'
                        placeholder="Motif"
                        label="Motif"
                        variant="outlined"
                        name="motif"
                        onChange={formik.handleChange}
                        value={formik.values.motif}
                        error={formik.touched.motif && Boolean(formik.errors.motif)}
                    />
                )} />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Observation"
            variant="outlined"
            name="observation"
            label="Observation"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.observation}
            error={
              formik.touched.observation && Boolean(formik.errors.observation)
            }
            type="text"
          />
        </Grid>

      </Grid>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
          variant="contained"
        >
          Valider
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {}}
          variant="contained"
        >
          Réinitialiser
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  createAbsentPersonAsync: (data: any) =>
    dispatch(createAbsentPersonnelAsync(data)),
  updateAbsentPersonAsync: (data: any) =>
    dispatch(updateAbsentPersonnelAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateAbsencePersonnelForm = connector(CreateAbsencePersonnelFormPage);

export default CreateAbsencePersonnelForm;
