import uniteFacturationTypes from "./uniteFacturation.types";

interface IUniteFacturationReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const uniteFacturationReducer = (
  state: IUniteFacturationReducerState = INITIAL_STATE,
  action: any
): IUniteFacturationReducerState => {
  switch (action.type) {
    case uniteFacturationTypes.GET_UNITE_FACTURATION_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case uniteFacturationTypes.SELECTED_UNITE_FACTURATION:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case uniteFacturationTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default uniteFacturationReducer;
