import { Navigate, RouteObject } from "react-router-dom";
import { routePaths } from "../config";
import { AdminIVEQIRoutes } from "@/features/admin-iveqi/routes/adminIVEQI.routes";

export const privateRoutes: RouteObject[] = [
  {
    path: routePaths.adminIVEQI+"/*",
    element: <AdminIVEQIRoutes/>,
  },
  { path: "*", element: <Navigate to={`${routePaths.adminIVEQI}`} /> }
];
