import alerteTypes from "./alerte.types";

interface IAlerteReducerState {
  list: any,
  count: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: {},
  count: {},
  itemSelected: {},
  loading: false,
  error: ""
};

const alerteReducer = (
  state: IAlerteReducerState = INITIAL_STATE,
  action: any
): IAlerteReducerState => {
  switch (action.type) {
    case alerteTypes.GET_ALERTE_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case alerteTypes.GET_ALERTE_NUMBER:
      return {
        ...state,
        count: action.payload,
        error: ""
      };

    case alerteTypes.SELECTED_ALERTE:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case alerteTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default alerteReducer;
