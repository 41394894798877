import { ToastGeneric } from "@/utils/toast";
import Axios from "axios";
import { API_URL } from "../config";

import { store } from "@/store";
import { logout } from "@/store/reducers/auth/auth.action";

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.response.use(
  (response:any) => {
    return response.data;
  },
  (error:any) => {
    const message = error.response?.data?.message || error.message;

    console.log(message);
    if (message === "Validation failed") ToastGeneric(`Problème de connexion, réessayez`, 'warning', 3000).fire();
    if (message === "Network Error") ToastGeneric(`Problème de connexion, réessayez`, 'error', 3000).fire();

    if (error.response.status === 401) {
      // UNAUTORIZED REQUEST ==> LOGOUT AUTOMATICALLY THE CURRENT CONNEXION
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);
