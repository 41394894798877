import React from "react";
import "./ListeItemCardGenerique.styles.scss";
// import engineer from '@/assets/images/png/engineer.png';
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const API_IMAGE = process.env.REACT_APP_API_URL_IMAGE as string;

const ListeItemCardGenerique: React.FC<any> = ({
  id,
  columns,
  data,
  setIsEdit,
  isActive,
  imageFieldName,
  imageAltText,
}) => {

  console.log('data card',data);
  
  const handleClick = (item:any) => {
    
    if (!item.statut_chantier || item.statut_chantier !== -1) {
      setIsEdit && setIsEdit(id);
    }
  };

  return (
    <>
      {data.map((item: any, index: any) => (
        <div
          className={`generic-item-card ${isActive ? "active" : ""}`}
          key={index}
          onClick={() => handleClick(item)}
        >
          {imageFieldName && (
            <img
              src={API_IMAGE + imageFieldName}
              alt={"image de " + imageAltText}
              className="imageStyle"
            />
          )}

          {/* <img src={engineer} alt={item[imageFieldName]} className='imageStyle' /> */}
          <div className={imageFieldName && "with-image"}>
            {columns.map((label: any, i: any) => (
              <div className="paragraph" key={i}>
                {label.headerName}:{" "}
                <b style={{ color: "#000" }}>
                  {label.headerName === "Debut" ||
                  label.headerName === "Fin" ||
                  label.headerName === "Date d'activité" ||
                  label.headerName === "Date d'affectation"
                    ? moment(item[label.field]).format("LL")
                    : label.headerName === "Statut"
                    ? item[label.field] === -1
                      ? <div className="bulle red"></div>
                      : <div className="bulle green"></div>
                    : ((label.headerName === "Prochain entretien" && (item['prochain_entretien'] < item['dernier_compteur'])) 
                      ? <b style={{color: 'red'}}>{item[label.field]}</b> 
                      :item[label.field])}
                </b>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ListeItemCardGenerique;
