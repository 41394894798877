import { InlineIcon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./CreateUtilisationEnginForm.style.scss";
import { connect } from "react-redux";
import {
  createUtilisationEnginAsync,
  updateUtilisationEnginAsync,
} from "@/store/reducers/engin/engin.action";
import { createStructuredSelector } from "reselect";
import "dayjs/locale/fr";
import { selectPersonnelItemList } from "@/store/reducers/personnel/personnel.selector";
import { getAllNatureTravauxAsync } from "@/store/reducers/nature-travaux/natureTravaux.action";
import { selectNatureTravauxItemList } from "@/store/reducers/nature-travaux/natureTravaux.selector";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import moment from "moment";

//const regexImmatriculation = /[0-9]{1,4}[A-Z]{2}01/

const CreateUtilisationEnginFormPage: React.FC<any> = ({
  selectedItem,
  getAllPersonnel,
  getAllType,
  getAllNatureTravauxAsync,
  createUtilisationEnginAsync,
  updateUtilisationEnginAsync,
  closeModal,
  userConnected,
  typeOperation
}) => {

  console.log("selectedItem",selectedItem);
  

  useEffect(() => {
    const url1: any = getAllNatureTravauxAsync();

    const fetchData = async () => {
      try {
        await fetch(url1);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [getAllNatureTravauxAsync]);

  const listPerson: any[] = [];

  getAllPersonnel.forEach((o: any) => {
    listPerson.push({
      ...o,
      value: o?.personnel?.nom + " " + o?.personnel?.prenoms,
      id: o?.personnel?.nom + " " + o?.personnel?.prenoms,
    });
  });

  const [chauffeur, setChauffeur] = useState<{ id: any; value: string }>(
    selectedItem
      ? { id: selectedItem?.chauffeur, value: selectedItem?.chauffeur }
      : { id: null, value: "" }
  );

  const handleChauffeurChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    if (value === null) {
      setChauffeur({ id: null, value: "" });
    } else {
      setChauffeur(value as { id: any; value: string });
    }
  };

  const travauxList: any = [];
    getAllType.forEach((x:any) => {
        travauxList.push({...x , value:x?.libelle , id:x?.libelle })
    });

    const [nature, setNature] = useState<{ id: any; value: string }>(
        selectedItem
          ? { id: selectedItem?.nature_travaux, value: selectedItem?.nature_travaux }
          : { id: null, value: "" }
      );
    
      const handleNatureChange = (
        event: any,
        value: { id: any; value: string } | null
      ) => {
        if (value === null) {
          setNature({ id: null, value: "" });
        } else {
          setNature(value as { id: any; value: string });
        }
      };

  // const [date, setDate] = React.useState<Dayjs | null>(selectedItem?.date  ? dayjs(selectedItem?.date) : dayjs());

  const [date, setDate] = useState(moment().format("YYYY-MM-DD HH:m:s"));

  // const validationSchema = yup.object({
  //   date: yup.string().required("Champ obligatoire"),
  //   compteur_debut: yup.string().required("Champ obligatoire"),
  //   compteur_fin: yup.string().required("Champ obligatoire"),
  //   carburant: yup.string().required("Champ obligatoire"),
  //   quantite: yup.string().required("Champ obligatoire"),
  // });

  const validationSchema = yup.object({
    date: yup.string().required("Champ obligatoire"),
    compteur_debut: yup.string().required("Champ obligatoire"),
    compteur_fin: yup
      .string()
      .required("Champ obligatoire")
      .test(
        "is-greater",
        "Le compteur de debut doit être supérieur au compteur de fin",
        function (value) {
          const { compteur_debut } = this.parent;
          return Number(value) > Number(compteur_debut);
        }
      ),
    carburant: yup.string().required("Champ obligatoire"),
    quantite: yup
      .string()
      .required("Champ obligatoire")
      .test(
        "is-less-than-24",
        "La quantité ne peut pas dépasser 24",
        function (value) {
          return Number(value) <= 24;
        }
      ),
  });

  const resetForm = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      date: date,
      chantierEnginId: selectedItem ? selectedItem?.chantierEnginId : '',
      enginId: selectedItem ? selectedItem?.id : '',
      travail: 'oui',
      compteur_debut: selectedItem ? selectedItem?.dernier_compteur : '',
      compteur_fin: selectedItem ? selectedItem?.compteur_fin : '',
      carburant: selectedItem ? selectedItem?.carburant : '',
      quantite: selectedItem ? selectedItem?.quantite : '',
      observation: selectedItem ? selectedItem?.observation : '',

      nature_travaux: nature?.value,
      chauffeur: chauffeur?.value,
      utilisateurId: userConnected?.user?.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      console.log("Values form", values);

      if (typeOperation === 'Creer une tache') {
        createUtilisationEnginAsync(values);
      } else {
        updateUtilisationEnginAsync(values);
      }
      closeModal();
    },
  });

  // Fonction pour calculer automatiquement la quantité
  const calculateQuantity = (compteurDebut: number, compteurFin: number) => {
    return compteurFin - compteurDebut;
  };


  useEffect(() => {
    const { compteur_debut, compteur_fin } = formik.values;
    if (compteur_debut && compteur_fin && compteur_fin > compteur_debut) {
      let quantity = calculateQuantity(compteur_debut, compteur_fin);

      if (quantity > 24) {
        alert("La quantité ne peut pas dépasser 24.");
        quantity = 24;
      }
      formik.setFieldValue("quantite", quantity);
    }else if (compteur_debut > compteur_fin){
      formik.setFieldValue("quantite", 0);
    }
  }, [formik.values.compteur_debut, formik.values.compteur_fin]);

  const currentDate = moment().format("YYYY-MM-DD");


  return (
    <form className="create-list-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {typeOperation === 'Creer une tache' ? 'Creer une tache' : 'Modifier une tache'}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>
        {/* <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
                formik.setFieldValue("date", dayjs(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid> */}

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            type="date"
            placeholder="Date"
            variant="outlined"
            name="date"
            label="date"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.date}
            inputProps={{ max: currentDate }}
            error={
              formik.touched.date &&
              Boolean(formik.errors.date)
            }
            // helperText={formik.touched.compteur_debut && formik.errors.compteur_debut}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Compteur debut"
            variant="outlined"
            name="compteur_debut"
            label="Compteur debut"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.compteur_debut}
            error={
              formik.touched.compteur_debut &&
              Boolean(formik.errors.compteur_debut)
            }
            // helperText={formik.touched.compteur_debut && formik.errors.compteur_debut}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Compteur fin"
            variant="outlined"
            name="compteur_fin"
            label="Compteur fin"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.compteur_fin}
            error={
              formik.touched.compteur_fin && Boolean(formik.errors.compteur_fin)
            }
            // helperText={formik.touched.compteur_fin && formik.errors.compteur_fin}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Quantite"
            variant="outlined"
            name="quantite"
           // label="Quantite"
            InputProps={{ readOnly: true }} // Ajouter cette ligne pour le mode lecture seule

            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.quantite}
            error={formik.touched.quantite && Boolean(formik.errors.quantite)}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            type="number"
            placeholder="Carburant"
            variant="outlined"
            name="carburant"
            label="Carburant"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.carburant}
            error={formik.touched.carburant && Boolean(formik.errors.carburant)}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-nature-select"
            options={travauxList}
            value={nature}
            onChange={handleNatureChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value || ''}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="nature"
                label="nature"
                variant="outlined"
                name="nature_travaux"
                onChange={formik.handleChange}
                value={(formik.values.nature_travaux = nature?.value)}
                error={
                  formik.touched.nature_travaux && Boolean(formik.errors.nature_travaux)
                }
                // helperText={formik.touched.chefChantierId && formik.errors.chefChantierId}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-chauffeur-select"
            options={listPerson}
            value={chauffeur}
            onChange={handleChauffeurChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value || ''}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Chauffeur"
                label="Chauffeur"
                variant="outlined"
                name="chauffeur"
                onChange={formik.handleChange}
                value={(formik.values.chauffeur = chauffeur.value)}
                error={
                  formik.touched.chauffeur && Boolean(formik.errors.chauffeur)
                }
                // helperText={formik.touched.chefChantierId && formik.errors.chefChantierId}
              />
            )}
          />
        </Grid>

        
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            type="text"
            placeholder="Observation"
            variant="outlined"
            name="observation"
            label="observation"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.observation}
            error={formik.touched.observation && Boolean(formik.errors.observation)}
          />
        </Grid>
      </Grid>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
          variant="contained"
        >
          Valider
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  getAllPersonnel: selectPersonnelItemList,
  getAllType: selectNatureTravauxItemList,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllNatureTravauxAsync: () => dispatch(getAllNatureTravauxAsync()),
  createUtilisationEnginAsync: (data: any) => dispatch(createUtilisationEnginAsync(data)),
  updateUtilisationEnginAsync: (data: any) => dispatch(updateUtilisationEnginAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateUtilisationEnginForm = connector(CreateUtilisationEnginFormPage);

export default CreateUtilisationEnginForm;
