import { routePaths } from "@/config";
import { Routes, Route, Navigate } from "react-router-dom";
import LCampaign from "../layouts/Campaign.content.layout";
import Accueil from "../pages/Accueil.page";
import Personnel from "../pages/Personnel.page";
// import Listes from "../pages/Listes.page";
import Engins from "../pages/Engins.page";
import Cuve from "../pages/Cuve.page";
import RapportEngin from "../pages/RapportEngin.page";
import RapportPersonnel from "../pages/RapportPersonnel.page";


export const CtCampaignRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LCampaign />}>
              <Route index element={ <Navigate to={`${routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute}`} /> } /> 
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.home} element={ <Accueil />} />
              {/* <Route path={routePaths.adminIVEQIChildren.campaignChildren.liste} element={ <Listes />} /> */}
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.cuve} element={ <Cuve />} />
              {/* <Route path={routePaths.adminIVEQIChildren.campaignChildren.organisation} element={ <Organisation />} /> */}
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.personnels} element={ <Personnel />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.engins} element={ <Engins />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.rapportEngin} element={ <RapportEngin />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.rapportPersonnel} element={ <RapportPersonnel />} />

              <Route path="/*" element={ <Navigate to={`${routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute}`} />} />
          </Route>
      </Routes>
    );
}; 