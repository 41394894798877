import carteTypes from "./carte.types";

interface ICarteReducerState {
  list_carte_chantier: any,
  list_carte_statistique: any,
  list_carte_engin_by_id_chantier: any,
  list_carte_person_by_id_chantier: any,
  list_carte_data_by_status: any,
  loading_carte_chantier: boolean,
  loading_carte_statistique: boolean,
  loading_carte_engin_by_id_chantier: boolean,
  loading_carte_person_by_id_chantier: boolean,
  loading_carte_data_by_status: boolean,
  error: string
}

const INITIAL_STATE = {
  list_carte_chantier: [],
  list_carte_statistique: [],
  list_carte_engin_by_id_chantier: [],
  list_carte_person_by_id_chantier: [],
  list_carte_data_by_status: [],
  loading_carte_chantier: false,
  loading_carte_statistique: false,
  loading_carte_engin_by_id_chantier: false,
  loading_carte_person_by_id_chantier: false,
  loading_carte_data_by_status: false,
  error: ""
};

const carteReducer = (
  state: ICarteReducerState = INITIAL_STATE,
  action: any
): ICarteReducerState => {
  switch (action.type) {
    case carteTypes.GET_CARTE_CHANTIER_LIST:
      return {
        ...state,
        list_carte_chantier: action.payload,
        error: ""
      };

    case carteTypes.GET_CARTE_STATISTIQUE_LIST:
      return {
        ...state,
        list_carte_statistique: action.payload,
        error: ""
      };

    case carteTypes.GET_ENGIN_LIST_BY_ID_CHANTIER:
      return {
        ...state,
        list_carte_engin_by_id_chantier: action.payload,
        error: ""
      };

    case carteTypes.GET_PERSON_LIST_BY_ID_CHANTIER:
      return {
        ...state,
        list_carte_person_by_id_chantier: action.payload,
        error: ""
      };

    case carteTypes.GET_DATA_LIST_BY_STATUS:
      return {
        ...state,
        list_carte_data_by_status: action.payload,
        error: ""
      };

    case carteTypes.LOADING_CHANTIER:
      return {
        ...state,
        loading_carte_chantier: action.payload,
        error: ""
      };

    case carteTypes.LOADING_STATISTIQUE:
      return {
        ...state,
        loading_carte_statistique: action.payload,
        error: ""
      };

    case carteTypes.LOADING_ENGIN:
      return {
        ...state,
        loading_carte_engin_by_id_chantier: action.payload,
        error: ""
      };

    case carteTypes.LOADING_PERSON:
      return {
        ...state,
        loading_carte_person_by_id_chantier: action.payload,
        error: ""
      };

    case carteTypes.LOADING_DATA_BY_STATUS:
      return {
        ...state,
        loading_carte_data_by_status: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default carteReducer;
