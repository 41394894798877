export enum carteTypes {
    LOADING_CHANTIER = "LOADING_CHANTIER",
    LOADING_DATA_BY_STATUS = "LOADING_DATA_BY_STATUS",
    LOADING_STATISTIQUE = "LOADING_STATISTIQUE",
    LOADING_ENGIN = "LOADING_ENGIN",
    LOADING_PERSON = "LOADING_PERSON",
    GET_CARTE_CHANTIER_LIST = "GET_CARTE_CHANTIER_LIST",
    GET_CARTE_STATISTIQUE_LIST = "GET_CARTE_STATISTIQUE_LIST",
    GET_PERSON_LIST_BY_ID_CHANTIER = "GET_PERSON_LIST_BY_ID_CHANTIER",
    GET_ENGIN_LIST_BY_ID_CHANTIER = "GET_ENGIN_LIST_BY_ID_CHANTIER",
    GET_DATA_LIST_BY_STATUS = "GET_DATA_LIST_BY_STATUS",
}
  
export default carteTypes;