import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectTableDonnee = (state:TRootState) => state.tableDonnee;

export const selectLoading = createSelector(
  [selectTableDonnee],
  (tableDonnee:any) => tableDonnee.loading
);

export const selectTableDonneeItemList = createSelector(
    [selectTableDonnee],
    (tableDonnee:any) => tableDonnee.list
);

export const selectTableDonneeDetailLoading = createSelector(
  [selectTableDonnee],
  (tableDonnee:any) => tableDonnee.loading
);

export const selectTableDonneeItemSelected = createSelector(
    [selectTableDonnee],
    (tableDonnee:any) => tableDonnee.itemSelected
);
