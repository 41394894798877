import React from 'react';
import CtPersonnelRh from '../pages-content/Personnel-content/Personnel.content';

const PersonnelRh:React.FC = () => {
  return (
    <>
      <CtPersonnelRh />
    </>
  )
}

export default PersonnelRh;