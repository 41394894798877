import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectAlerte = (state:TRootState) => state.alerte;

export const selectLoading = createSelector(
  [selectAlerte],
  (alerte:any) => alerte.loading
);

export const selectAlerteItemList = createSelector(
    [selectAlerte],
    (alerte:any) => alerte.list
);

export const selectAlerteCountList = createSelector(
  [selectAlerte],
  (alerte:any) => alerte.count
);

export const selectAlerteItemSelected = createSelector(
    [selectAlerte],
    (alerte:any) => alerte.itemSelected
);
