import React from 'react';
import CtRapportEnginCampaign from '../pages-content/RapportEngin-content/RapportEngin.content';

const RapportEngin:React.FC = () => {
  return (
    <>
      <CtRapportEnginCampaign/>
    </>
  )
}

export default RapportEngin;