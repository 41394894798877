import React from 'react';
import CtEnginsAdministration from '../pages-content/Engins-content/Engins.content';

const Engins:React.FC = () => {
  return (
    <>
      <CtEnginsAdministration />
    </>
  )
}

export default Engins;