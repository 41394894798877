import { InlineIcon } from '@iconify/react';
import avatar from '@/assets/images/png/engineer.png';
import {  Button,  Divider,  Grid, TextField, } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './EditProfilForm.style.scss';
import { editUserConnectedProfilAsync } from '@/store/reducers/user/user.action';
import { connect } from 'react-redux';

const EditProfilFormPage: React.FC<any> = ({
 data,
 editUserConnectedProfilAsync
}) => {



    // const [profil, setProfil] = useState<{ id: string, value: string }>({ id: '', value: '' });
    // const handleProfilChange = (event: any, value: { id: string, value: string } | null) => {
    //     console.log(value);
    //     if (value == null) {
    //         setProfil({ id: '', value: '' });
    //     } else {
    //         setProfil(value as { id: string, value: string });
    //     }
    // };

    const validationSchema = yup.object({
        nom: yup.string().required(" Le nom de la liste est obligatoire "),
        prenoms: yup.string().required(" Le prenoms de la liste est obligatoire "),
        email: yup.string().required(" L'email de la liste est obligatoire "),
        telephone: yup.string().required(" Le telephone de la liste est obligatoire "),
        matricule: yup.string().required(" Le matricule de la liste est obligatoire "),
        profilId: yup.string().required(" Le profilId de la liste est obligatoire "),
        fonction: yup.string().required(" La fonction de la liste est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: data ? data.id : null,
            nom: data ? data.nom : '',
            prenoms: data ? data.prenoms : '',
            email: data ? data.email : '',
            telephone: data ? data.telephone : '',
            matricule: data ? data.matricule : '',
            profilId: data ? data.profil.libelle : '',
            fonction: data ? data.fonction : ''
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            editUserConnectedProfilAsync(values)
            
        }
    });



    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> Modification du profil</span>

            <div className="avatar">
                <img src={avatar} alt="" />
            </div>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Nom"
                        variant="outlined"
                        name="nom"
                        label="Nom"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.nom}
                        error={formik.touched.nom && Boolean(formik.errors.nom)}
                        // helperText={formik.touched.nom && formik.errors.nom}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Prénoms"
                        variant="outlined"
                        name="prenoms"
                        label="Prénoms"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.prenoms}
                        error={formik.touched.prenoms && Boolean(formik.errors.prenoms)}
                        // helperText={formik.touched.prenoms && formik.errors.prenoms}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Email"
                        variant="outlined"
                        name="email"
                        label="Email"
                        disabled
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        // helperText={formik.touched.email && formik.errors.email}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Matricule"
                        variant="outlined"
                        name="matricule"
                        label="Matricule"
                        disabled
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.matricule}
                        error={formik.touched.matricule && Boolean(formik.errors.matricule)}
                        // helperText={formik.touched.matricule && formik.errors.matricule}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Téléphone"
                        variant="outlined"
                        name="telephone"
                        label="Téléphone"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.telephone}
                        error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                        // helperText={formik.touched.telephone && formik.errors.telephone}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Fonction"
                        variant="outlined"
                        name="fonction"
                        label="Fonction"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.fonction}
                        error={formik.touched.fonction && Boolean(formik.errors.fonction)}
                        // helperText={formik.touched.fonction && formik.errors.fonction}
                        type="text" />
                </Grid>



                <Grid item xs={12} sm={12} lg={12} xl={6} >
                        {/* <Autocomplete
                        id="id-service-select"
                        options={profilList}
                        value={profil}
                        onChange={handleProfilChange}
                        autoHighlight
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Profil"
                                label="Profil"
                                variant="outlined"
                                name="profilId"
                                onChange={formik.handleChange}
                                value={formik.values.profilId = profil.id}
                                error={formik.touched.profilId && Boolean(formik.errors.profilId)}
                                // helperText={formik.touched.profilId && formik.errors.profilId}
                            />
                        )} /> */}
                </Grid>
            </Grid>


      <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option-profil'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:edit-pencil" />}
                    variant='contained'>
                    Modifier
                </Button>

                {/* <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    variant='contained'>
                    Réinitialiser
                </Button> */}
            </div>

        </form>
    )
}

const mapDispatchToProps = (dispatch:any) => ({
    editUserConnectedProfilAsync: (data:any) => dispatch(editUserConnectedProfilAsync(data)),
  });
  
  const connector = connect(mapDispatchToProps);
  const EditProfilForm = connector(EditProfilFormPage);

export default EditProfilForm

