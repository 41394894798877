import { CuveService } from "@/services/cuve.service";
import { cuveTypes } from "./cuve.types";

export const setLoading = (loading:any) => ({
    type: cuveTypes.LOADING,
    payload: loading,
});

export const getAllCuves = (list:any) => ({
    type: cuveTypes.GET_CUVE_LIST,
    payload: list,
});

export const getCuveDetail = (list:any) => ({
  type: cuveTypes.GET_CUVE_DETAIL,
  payload: list,
});

export const selectedItem = (cuve:any) => ({
  type: cuveTypes.SELECTED_CUVE,
  payload: cuve,
});

 
export const  getAllCuvesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CuveService.getCuves();
};

export const  createCuveDetailAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CuveService.getCuveDetail();
};

export const  approvisionCuveAsync =  (cuve:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CuveService.approvisionCuve(cuve);
};

export const  retraitCuveAsync =  (cuve:any) =>
(dispatch:any) => {
  console.log("Cuve to service", cuve);
  dispatch(setLoading(true));
  CuveService.retraitCuve(cuve);
};

export const deleteCuveAsync =  (id:any) =>
(dispatch:any) => {
  console.log("Cuve to service", id);
  dispatch(setLoading(true));
  CuveService.deleteCuve(id);
};





