import MiniPiehart from "@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart";
import { InlineIcon } from "@iconify/react";
import {
  TuneRounded,
  TurnedIn,
  ListAlt,
  Edit,
  PauseCircleOutline,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import SideModal from "../../components/SideModal/SideModal";
import "./Engins.content.style.scss";
import { DataGrid, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import ListeItemCardGenerique from "@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique";
import {
  selectEnginItemList,
  selectIncidentItemList,
  selectLoading,
  selectUtilisationByEngin,
} from "@/store/reducers/engin/engin.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Stack } from "@mui/material";
import {
  getAllEnginsAsync,
  getAllEnginsIncidentAsync,
  getUtilisationByEnginAsync,
} from "@/store/reducers/engin/engin.action";
import { selectChantierItemSelected } from "@/store/reducers/chantier/chantier.selector";
import { List, Modal } from "antd";
import moment from 'moment';
import CreatePanneEnginForm from "../../Forms/CreatePanneEnginForm/CreatePanneEnginForm";
import CreateUtilisationEnginForm from "../../Forms/CreateUtilisationEnginForm/CreateUtilisationEnginForm";
import CreateNoActiviteEnginForm from "../../Forms/CreateNoActiviteEnginForm/CreateNoActiviteEnginForm";

const columns = [
  {
    field: "statut",
    headerName: "Statut",
    width: 150,
    renderCell: (params: GridRenderCellParams) => {
      
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            backgroundColor: (params?.row?.statut === 1) ? 'green' : 'red',
            width: "90%",
            height: "15px",
            borderRadius: "6px"
          }}
        >
          {(params?.row?.statut === 1) ? <span>Fonctionnel</span> : <span>Panne</span>}
        </Box>
      );
    }
  },
  { field: "libelle", headerName: "Libellé", width: 280 },
  { field: "num_parc", headerName: "N°Parc", width: 150 },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    width: 200,
    valueGetter: (params: GridValueGetterParams) =>
      params?.row?.description?.slice(0, 20) || "",
  },
  {
    field: "dernier_compteur",
    headerName: "Compteur Engin",
    width: 150,
  },
  {
    field: "date_dernier_compteur",
    headerName: "Date d'activité",
    width: 150,
  },
  {
    field: "quantite_a_realiser",
    headerName: "Quantité a réaliser",
    width: 150,
  },
  {
    field: "quantite_realise",
    headerName: "Quantité réalisée",
    width: 150,
  },
  {
    field: "totalCarburant",
    headerName: "Carburant",
    width: 150,
  },
  {
    field: "totalQuantite",
    headerName: "Heure de travail",
    width: 150,
  },
  {
    field: "prochain_entretien",
    headerName: "Prochain entretien",
    width: 150,
  },
 
  
];



const CtEnginsAdministrationPage: React.FC<any> = ({
  loading,
  enginList,
  incidentList,
  utilisationByEngin,
  chantierSelected,
  getUtilisationByEnginAsync,
  getAllEnginsIncidentAsync
}) => {

  useEffect(() => {
      
      const url:any = getAllEnginsIncidentAsync();  
      const fetchData = async () => {
          try {
              await fetch(url);
          } catch (error) {
              console.log("error", error);
          }
      };
      fetchData();
    
  }, [getAllEnginsIncidentAsync]);

  const [searchText, setSearchText] = useState("");
  const [typeOperation, setTypeOperation] = useState("Creer une tache");
  const [isCardMode, setIsCardMode] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    isCardMode && setSelection(null);
  };

  const [isAdd, setIsAdd] = useState(false);
  const [selection, setSelection] = React.useState<any>(null);
  const [openHistorique, setOpenHistorique] = useState<boolean>(false);
  const [openListeIncident, setOpenListeIncident] = useState<boolean>(false);

  const listItems: any[] = [];
  enginList.forEach((x: any) => {
    listItems.push({
      ...x,
      ...x.engin,
      chantierEnginId:x.id,
      // situation: (x.chantierId) ? x.chantier?.nom +' à '+x.chantier?.localisation : 'Non Loué',
      searchText:
        x.engin.libelle + " " + x.engin.description + x.engin.num_parc,
    });
  });

  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (
        value.searchText
          .toLocaleLowerCase()
          .indexOf(searchText.toLocaleLowerCase() || "") !== -1
      );
    });
  };

  // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
  // const handleExportExcel = () => {
  //   exportToExcel(listItems, columns, 'engin');
  // };

  console.log('chantierSelected', chantierSelected)

  const selectItemFunc = (idElt: number | any) => {
    const element = getListItemsFilteredSearch().find(
      (x: any) => x.id === idElt
    );
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }

    setSelection(element);
   
    console.log("element?.id", element?.id);
  };

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard;
  };

  return (
    <>
      <div className="ct-engins-campagne">
        <div className="ct-engins-campagne__card">
          <div className="header-part">
            <div className="title-part">
              <div className="title-bloc">
                <span className="title"> Gestion des engins </span>
                <Chip
                  label={` ${listItems?.length} ${
                    listItems?.length < 2 ? "engin" : "engins"
                  }  `}
                  className="total-number-chip"
                />

                <Badge color="secondary">
                  <IconButton
                    className="close-icon"
                    size="small"
                    color="blue"
                    onClick={() => {
                      handleOpen();
                      setIsAdd(false);
                    }}
                  >
                    <TuneRounded />
                  </IconButton>
                </Badge>
                <Badge color="secondary" variant="dot">
                  <IconButton
                    className="close-icon"
                    title={`Mode ${isCardMode ? "liste" : "carte"}`}
                    size="small"
                    color="blue"
                    onClick={() => {
                      setIsCardMode(!isCardMode);
                      setSelection(null);
                    }}
                  >
                    {isCardMode ? <ListAlt /> : <TurnedIn />}
                  </IconButton>
                </Badge>
              </div>
              <div className="toolbar-part">
                <div className="actions-part">
                  {selection ? (
                    <>
                      <Divider orientation="vertical" />

                      {(selection?.statut === 1) &&<Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setIsAdd(true);
                          setTypeOperation("Creer une tache");
                          setSelection(selection);
                          handleOpen();
                        }}
                        startIcon={<Edit />}
                      >
                        Creer une tache
                      </Button>}
                      <Divider orientation="vertical" />

                      {(selection?.statut === 1) && <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setIsAdd(true);
                          setTypeOperation("Pas d'activité");
                          setSelection(selection);
                          handleOpen();
                        }}
                        startIcon={<PauseCircleOutline />}
                      >
                        Pas d'activité
                      </Button>}

                      <Divider orientation="vertical" />

                      {(selection?.statut === 1) && <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setIsAdd(true);
                          setTypeOperation("Declarer une panne");
                          setSelection({
                            chantierId: selection?.chantierId,
                            id: selection?.id,
                            enginId: selection?.enginId,
                            statut: selection?.statut,
                            chantierEnginId: selection?.chantierEnginId
                          });
                          handleOpen();
                        }}
                        startIcon={<InlineIcon icon="carbon:document-import" />}
                      >
                        Declarer une panne
                      </Button>}

                      {(selection?.statut === -1) && <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setIsAdd(true);
                          setTypeOperation("Modifier une panne");
                          setSelection({
                            chantierId: selection?.chantierId,
                            id: selection?.id,
                            enginId: selection?.enginId,
                            statut: selection?.statut,
                            chantierEnginId: selection?.chantierEnginId
                          });
                          handleOpen();
                        }}
                        startIcon={<InlineIcon icon="carbon:document-import" />}
                      >
                        Modifier une panne
                      </Button>}

                      <Divider orientation="vertical" />
                      <Button
                        color="blue"
                        className="btn-option"
                        onClick={() => {
                          setOpenHistorique(true);
                          getUtilisationByEnginAsync(selection?.id);

                        }}
                        //variant='contained'
                        startIcon={<InlineIcon icon="carbon:document-import" />}
                      >
                        Historique
                      </Button>
                    </>
                  ) : (
                    <>
                      <Divider orientation="vertical" />
                      <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            setOpenListeIncident(true);
                          }}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Liste des pannes
                        </Button>
                    </>
                  )}
                </div>

                <TextField
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className="search-input"
                  placeholder="Recherche ... "
                  variant="outlined"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InlineIcon icon="bi:search" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <Divider className="custom-divider" />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames("kpi-card ")}>
                <span className="value">{listItems?.length}</span>
                <span className="text">Total Engins</span>
                <div className="mini-pie">
                  <MiniPiehart
                    value1={15}
                    value2={50}
                    value3={35}
                    value4={0}
                    color1={"#EF7D00"}
                    color2={"#26353F"}
                    color3={"#008F75"}
                    color4={"#ff1744"}
                    height={30}
                    width={30}
                    interRadius={5}
                  />
                </div>
              </div>
            </div>
            <>
              {loading && (
                <>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={300}
                    height={120}
                  />
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    width={300}
                    height={120}
                  />
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={300}
                    height={120}
                  />
                </>
              )}

              {isCardMode ? (
                <InfiniteScroll
                  className="content-part__infinite-scroll-part"
                  scrollableTarget="scrollableDiv"
                  dataLength={listItems?.length}
                  next={getAllEnginsAsync}
                  hasMore={true}
                  loader={
                    listItems ? (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={300}
                        height={120}
                      />
                    ) : (
                      <p> Aucune donnée disponible </p>
                    )
                  }
                >
                  {!loading &&
                    listItems
                      .filter((value: any) => {
                        return (
                          value.searchText
                            .toLocaleLowerCase()
                            .indexOf(searchText.toLocaleLowerCase() || "") !==
                          -1
                        );
                      })
                      .map((value: any, index: any) => {
                        return (
                          <ListeItemCardGenerique
                            id={value.id}
                            key={index}
                            setOpen={setOpen}
                            columns={columns}
                            data={[value]}
                            setIsEdit={selectItemFunc}
                            isActive={isCardActive(value.id)}
                            btnLibelle="Voir ses utilisations"
                            dataDetail={
                              utilisationByEngin?.utilisation_engin || []
                            }
                            detail={
                              <List
                                itemLayout="horizontal"
                                style={{
                                  height: "400px",
                                  overflow: "auto",
                                }}
                                dataSource={
                                  utilisationByEngin?.utilisation_engin
                                }
                                renderItem={(item: any, index: any) => (
                                  <List.Item key={index}>
                                    <List.Item.Meta
                                      title={`${item?.date} | ${item?.nature_travaux}`}
                                      description={`M. ${item?.chauffeur}, chauffeur de l'engin ${value?.num_parc}, a effectue cette tache `}
                                      avatar={<Edit />}
                                    />
                                  </List.Item>
                                )}
                              />
                            }
                          />
                        );
                      })}
                </InfiniteScroll>
              ) : (
                <div style={{ height: "85%", width: "100%" }}>
                  <DataGrid
                    components={{
                      NoRowsOverlay: () => (
                        <Stack
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          Pas de donnees
                        </Stack>
                      ),
                    }}
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                          `${from} - ${to} Total: ${count}`,
                      },
                    }}
                    rows={getListItemsFilteredSearch()}
                    columns={columns}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 7 } },
                    }}
                    onRowSelectionModelChange={(row) => {
                      selectItemFunc(row[0]);
                    }}
                  />
                </div>
              )}
            </>
          </PerfectScrollbar>
        </div>
      </div>

      <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
        <>
          {(isAdd && (typeOperation==='Creer une tache' || typeOperation==='Modifier une tache')) && 
            <CreateUtilisationEnginForm
              closeModal={handleClose}
              selectedItem={selection}
              typeOperation={typeOperation}
            />
          }
          {(isAdd && (typeOperation==='Declarer une panne' || typeOperation==='Modifier une panne')) &&
            <CreatePanneEnginForm
              closeModal={handleClose}
              selectedItem={selection}
              typeOperation={typeOperation}
            />
          }

          {(isAdd && (typeOperation==="Pas d'activité")) &&
            <CreateNoActiviteEnginForm
              closeModal={handleClose}
              selectedItem={selection}
              typeOperation={typeOperation}
            />
          }
        </>
      </SideModal>


      {selection?.id && (
        <Modal
          title={
            <p>
              Historique (
              {utilisationByEngin?.utilisation_engin?.length || "N/A"})
            </p>
          }
          footer={<></>}
          open={openHistorique}
          onCancel={() => setOpenHistorique(false)}
        >
          <List
            itemLayout="horizontal"
            style={{
              height: "400px",
              overflow: "auto",
            }}
            dataSource={utilisationByEngin?.utilisation_engin}
            renderItem={(item: any, index: any) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={`${moment(item?.date).format("LL")} | ${item?.nature_travaux}`}
                  description={`M. ${item?.chauffeur}, chauffeur de l'engin ${selection?.num_parc}, a effectue cette tache `}
                  avatar={<Edit 
                    onClick={()=>{
                      setSelection(item);
                      handleOpen();
                      setIsAdd(true);
                      setTypeOperation("Modifier une tache");
                    }} 
                  />}
                />
              </List.Item>
            )}
          />
        </Modal>
      )}

      
        <Modal
          title={
            <p>
              Liste des incidents (
              {incidentList?.length || "N/A"})
            </p>
          }
          footer={<></>}
          open={openListeIncident}
          onCancel={() => setOpenListeIncident(false)}
        >
          <List
            itemLayout="horizontal"
            style={{
              height: "400px",
              overflow: "auto",
            }}
            dataSource={incidentList}
            renderItem={(item: any, index: any) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={`${moment(item?.date).format("LL")} | Type de panne: ${item?.panne}`}
                  description={`M. ${item?.nom_technicien}, technicien en charge du depannage de l'engin ${item?.engin?.num_parc} jusqu'au ${moment(item?.chantier?.date_depannage).format("LL")} `}
                  // avatar={<Edit />}
                />
              </List.Item>
            )}
          />
        </Modal>
    
    </>
  );
};

const mapStateToProps: any = createStructuredSelector({
  loading: selectLoading,
  enginList: selectEnginItemList,
  incidentList: selectIncidentItemList,
  utilisationByEngin: selectUtilisationByEngin,
  chantierSelected: selectChantierItemSelected,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllEnginsIncidentAsync: () =>
    dispatch(getAllEnginsIncidentAsync()),
  getUtilisationByEnginAsync: (id: any) =>
    dispatch(getUtilisationByEnginAsync(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CtEnginsAdministration = connector(CtEnginsAdministrationPage);

export default CtEnginsAdministration;
