import LContainer from '@/components/Layouts/Container/Container.layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './Authentication.styles.scss';

const LAuthentication: React.FC = () => {
  return (
    <div className="authentication-layout-container">
        <LContainer>
            <div className="form-container">
              <Outlet />
              <div className="footer">
                <span>Copyriht © - IVEQI, 2023, tous droits reservés ®</span>
              </div>
            </div>
        </LContainer>
    </div>
  )
}

export default LAuthentication;
