import { routePaths } from "@/config";
import { Navigate, Route, Routes } from "react-router-dom";
import  LAuthentication  from "../layouts/Authentication.layout";
import CtWelcomePortail from "../page-contents/WelcomePortail-content/WelcomePortail.content";
import  ForgottenPassword  from "../pages/ForgottenPassword.page";
import ResetPassword from "../pages/ResetPassword.page";
import  Signin  from "../pages/Signin.page";

export const AuthRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LAuthentication />}>
              <Route index element={ <Signin />} />
              <Route path={routePaths.authChildren.signIn} element={ <Signin />} />
              <Route path={routePaths.authChildren.welcomPortail} element={ <CtWelcomePortail />} />
              <Route path={routePaths.authChildren.forgottenPassword} element={ <ForgottenPassword />} />
              <Route path={routePaths.authChildren.resetPassword} element={ <ResetPassword />} />
              <Route path="/*" element={ <Navigate to={`${routePaths.authChildren.signInAbsolute}`} />} />
          </Route>
      </Routes>
    );
};