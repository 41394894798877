import React from 'react';
import CtCuveCampaign from '../pages-content/Cuve-content/Cuve.content';
import CtCuve2Campaign from '../pages-content/Cuve2-content/Cuve2.content';

const Cuve:React.FC = () => {
  return (
    <>
      <CtCuve2Campaign/>
    </>
  )
}

export default Cuve;