import categorieEnginTypes from "./categorieEngin.types";

interface ICategorieEnginReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const categorieEnginReducer = (
  state: ICategorieEnginReducerState = INITIAL_STATE,
  action: any
): ICategorieEnginReducerState => {
  switch (action.type) {
    case categorieEnginTypes.GET_CATEGORIE_ENGIN_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case categorieEnginTypes.SELECTED_CATEGORIE_ENGIN:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case categorieEnginTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default categorieEnginReducer;
