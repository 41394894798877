import React from 'react';
import { Alert } from 'antd';
import './AlertItem.styles.scss';

const AlertItem: React.FC<any> = ({type, msg, desc}) => {
    
  return (
    <Alert
        message={msg}
        description={desc}
        type={type}
        showIcon
    />
  )
}

export default AlertItem