import clientTypes from "./client.types";

interface IClientReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const clientReducer = (
  state: IClientReducerState = INITIAL_STATE,
  action: any
): IClientReducerState => {
  switch (action.type) {
    case clientTypes.GET_CLIENT_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case clientTypes.SELECTED_CLIENT:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case clientTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default clientReducer;
