import congesTypes from "./conges.types";

interface ICongesReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: ""
};

const congesReducer = (
  state: ICongesReducerState = INITIAL_STATE,
  action: any
): ICongesReducerState => {
  switch (action.type) {
    case congesTypes.GET_CONGES_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case congesTypes.SELECTED_CONGES:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case congesTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default congesReducer;
