import jwt_decode from "jwt-decode";
import authTypes from "./auth.types";

interface IAuthReducerState {
  user: any | null,
  password: string | null,
  jwt: any | null,
  otpData: any | null,
  isOptSent: boolean,
  loading: boolean,
  rememberMe: boolean,
  errors: string,
}

const INITIAL_STATE = {
    user: null,
    password: null,
    jwt: "",
    otpData: "",
    isOptSent: false,
    loading: false,
    rememberMe: false,
    errors: "",
};

const authReducer = (
  state: IAuthReducerState = INITIAL_STATE,
  action:any
): IAuthReducerState => {
  switch (action.type) {
    case authTypes.GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        errors: "",
      };

    case authTypes.GET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

    case authTypes.SEND_OPT_CODE:
      return {
        ...state,
        isOptSent: action.payload,
      };

    case authTypes.CATCH_OTP_DATA:
      return {
        ...state,
        otpData: action.payload,
      };

    case authTypes.GET_USER_TOKEN:
      return {
        ...state,
        jwt: action.payload,
        user: jwt_decode(action.payload)
      };

    case authTypes.AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case authTypes.AUTH_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case authTypes.REMEMBER_ME_AUTH:
      return {
        ...state,
        rememberMe: action.payload,
      };

    case authTypes.LOGOUT:
      return {
        ...state,
        user: null,
        loading: false,
        isOptSent: false,
        otpData: null,
        rememberMe: false,
        jwt: null,
        errors: "",
      };

    default:
      return state;
  }
};

export default authReducer;
