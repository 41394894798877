import React, { useState , useEffect } from "react";
import "./Header.container.styles.scss";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Select } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { routePaths } from "@/config";
import logo from "@/assets/images/png/logo.png";
import { InlineIcon } from "@iconify/react";

//redux part
import { logout } from "@/store/reducers/auth/auth.action";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import CenteredModal from "../../pages-content/Administration-content/components/CenteredModal/CenteredModal";
import EditProfilForm from "../../pages-content/Administration-content/Forms/EditProfilForm/EditProfilForm";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import ChangePasswordForm from "../../pages-content/Administration-content/Forms/ChangePasswordForm/ChangePasswordForm";
import { selectChantierItemList, selectLoading } from "@/store/reducers/chantier/chantier.selector";
import { store } from "@/store";
import { selectedItem } from "@/store/reducers/chantier/chantier.action";
import { getAllPersonnelsAsync } from "@/store/reducers/personnel/personnel.action";
import { getAllEnginsAsync } from "@/store/reducers/engin/engin.action";
import { getAllCuvesAsync } from "@/store/reducers/cuve/cuve.action";
import { selectAlerteCountList } from "@/store/reducers/alerte/alerte.selector";
import { getAllAlertesAsync, getAllCountAlertesAsync } from "@/store/reducers/alerte/alerte.action";

const HeaderCIEPage: React.FC<any> = ({
  userConnected,
  getChantiers,
  alerte,
  loading,
  getAllPersonnelsAsync,
  getAllEnginsAsync,
  getAllCuvesAsync,
  getAllCountAlertesAsync,
  getAllAlertesAsync
}) => {

  console.log('getChantiers nav',localStorage.getItem("chantierId"));
  console.log('getChantiers nav',localStorage.getItem("chantierLibelle"));
 
  useEffect(() => {

    if (localStorage.getItem("chantierId")) {
      
      const url:any = getAllPersonnelsAsync();      
      const url1:any = getAllEnginsAsync();      
      const url2:any = getAllCuvesAsync();      
      const url3:any = getAllCountAlertesAsync();      
      const url4:any = getAllAlertesAsync();     
      const fetchData = async () => {
          try {
              await fetch(url);
              await fetch(url1);
              await fetch(url2);
              await fetch(url3);
              await fetch(url4);
          } catch (error) {
              console.log("error", error);
          }
      };
      fetchData();
    }
    
  }, [getAllPersonnelsAsync, getAllEnginsAsync, getAllCuvesAsync, getAllCountAlertesAsync, getAllAlertesAsync]);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const theme = useTheme();
  const matches_down_lg = useMediaQuery(theme.breakpoints.down("lg"));
  const matches_down_md = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const location = useLocation();

  const getOut = () => {
    localStorage.removeItem("chantierId");
    localStorage.removeItem("chantierLibelle");
    dispatch(logout());
    window.location.reload();
  };

  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const onChange = (value: any) => {
    console.log(`selected ${value}`);
    
    localStorage.setItem("chantierId", value);
    getChantiers.forEach((o:any) => {
      if (o?.id === value) {
        localStorage.setItem("chantierLibelle", o?.nom);
      }
    });
    
    store.dispatch(selectedItem(value));
    getAllPersonnelsAsync(value);
    getAllEnginsAsync(value);
    getAllCuvesAsync(value);
    getAllCountAlertesAsync(value);
    getAllAlertesAsync(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div className="cie-header">
        <div className="top-part">
          <img src={logo} alt="logo" className="logo" />
          <Select
            style={{width: "200px"}}
            showSearch
            placeholder="Choisissez un chantier"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            status="warning"
            loading={loading}
            options={getChantiers?.map((x:any) => {
              return {
                ...x,
                label: x?.nom,
                value: x.id,
              }
            })}
            defaultValue={localStorage.getItem("chantierLibelle")}
          />

          <div className={classNames("infos-user")} onClick={handleClick}>
            {!matches_down_md && (
              <>
                <div className="text">
                  <span className="fullname">
                    {" "}
                    {userConnected?.user?.nom +
                      " " +
                      userConnected?.user?.prenoms}{" "}
                  </span>
                  <span className="role">
                    {" "}
                    {userConnected?.user?.profil?.libelle}{" "}
                  </span>
                </div>
              </>
            )}
            <div className="avatar-part">
              <InlineIcon icon="carbon:user-avatar-filled-alt" />
            </div>
            {!matches_down_lg ? (
              <div className="icon-end">
                <InlineIcon icon="akar-icons:chevron-down" />
              </div>
            ) : (
              <div className="icon-end" style={{ transform: "scale(2)" }}>
                <InlineIcon icon="heroicons-solid:menu-alt-3" />
              </div>
            )}
          </div>
        </div>
        {!matches_down_lg && (
          <>
            <div className="nav-part">
              {/* <NavLink
                to={routePaths.adminIVEQIChildren.dashboardAbsolute}
                className={
                  "nav-link" +
                  (location.pathname.indexOf(
                    routePaths.adminIVEQIChildren.dashboardAbsolute
                  ) !== -1
                    ? " selected"
                    : "")
                }
              >
                <InlineIcon icon="akar-icons:dashboard" />
                Tableau de bord
              </NavLink> */}
              {/* )} */}

                <>
                  <NavLink
                    to={routePaths.adminIVEQIChildren.campaignAbsolute}
                    className={
                      "nav-link" +
                      (location.pathname.indexOf(
                        routePaths.adminIVEQIChildren.campaignAbsolute
                      ) !== -1
                        ? " selected"
                        : "")
                    }
                  >
                    <InlineIcon icon="bi:tools"  />

                      <Badge badgeContent={(alerte?.fin_chantier + alerte?.incident + alerte?.personnel + alerte?.vidange + alerte?.cuve) || 0} color="warning">
                      Gestion des chantiers
                    </Badge>
                    
                  </NavLink>
                </>
            
            </div>
          </>
        )}
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {matches_down_md && (
          <MenuItem>
            <div className="menu-text">
              <span className="fullname">
                {" "}
                {userConnected?.user?.nom +
                  " " +
                  userConnected?.user?.prenoms}{" "}
              </span>
              <span className="role">
                {" "}
                {userConnected?.user?.profil?.libelle}
              </span>
            </div>
          </MenuItem>
        )}
        {matches_down_md && <Divider />}

        

       
          <>
            {matches_down_lg && (
              <MenuItem>
                <NavLink
                  to={routePaths.adminIVEQIChildren.campaignAbsolute}
                  className={
                    "menu-cie-nav-link" +
                    (location.pathname.indexOf(
                      routePaths.adminIVEQIChildren.campaignAbsolute
                    ) !== -1
                      ? " selected"
                      : "")
                  }
                >
                  <InlineIcon icon="bi:tools" />
                  <Badge badgeContent={(alerte?.vidange + alerte?.cuve + alerte?.incident+ alerte?.personnel) || 0} color="warning"></Badge>
                  Gestion des chantiers
                </NavLink>
              </MenuItem>
            )}
          </>
   

        {matches_down_lg && <Divider />}
        <MenuItem
          onClick={() => {
            handleOpenModal();
            setIsPasswordEdit(false);
          }}
        >
          <ListItemIcon>
            <InlineIcon
              icon="fluent:window-multiple-swap-20-filled"
              className="from-menu"
            />
          </ListItemIcon>
          Profil
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleOpenModal();
            setIsPasswordEdit(true);
          }}
        >
          <ListItemIcon>
            <InlineIcon
              icon="material-symbols:keyboard"
              className="from-menu"
            />
          </ListItemIcon>
          Changer de mot de passe
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickOpenLogout}>
          <ListItemIcon>
            <InlineIcon icon="charm:power" className="from-menu" />
          </ListItemIcon>
          Déconnexion
        </MenuItem>
      </Menu>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Déconnexion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de
            vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="tertiary" onClick={handleCloseLogout}>
            Annuler
          </Button>
          <Button color="tertiary" onClick={getOut} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>

      <CenteredModal
        open={openModal}
        onClose={handleCloseModal}
        closeClick={handleCloseModal}
      >
        <>
          {isPasswordEdit ? (
            <ChangePasswordForm />
          ) : (
            <EditProfilForm data={userConnected?.user || {}} />
          )}
        </>
      </CenteredModal>
    </>
  );
};

const mapStateToProps: any = createStructuredSelector({
  loading: selectLoading,
  userConnected: selectAuthUser,
  alerte: selectAlerteCountList,
  getChantiers: selectChantierItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
  getAllEnginsAsync: () => dispatch(getAllEnginsAsync()),
  getAllCuvesAsync: () => dispatch(getAllCuvesAsync()),
  getAllCountAlertesAsync: () => dispatch(getAllCountAlertesAsync()),
  getAllAlertesAsync: () => dispatch(getAllAlertesAsync()),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const HeaderCIE = connector(HeaderCIEPage);

export default HeaderCIE;
