import React from 'react';
import CtRapportPersonnel from '../pages-content/RapportPersonnel-content/RapportPersonnel.content';

const RapportPersonnel:React.FC = () => {
  return (
    <>
      <CtRapportPersonnel/>
    </>
  )
}

export default RapportPersonnel;