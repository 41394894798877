
import React, { useState } from 'react';
import { routePaths } from '@/config';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import logoApp from "@/assets/images/png/logo.png";
import './ResetPasswordForm.styles.scss';
import { Icon } from '@iconify/react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { updatePasswordAsync } from '@/store/reducers/auth/auth.action';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getOtpData, selectAuthLoading } from '@/store/reducers/auth/auth.selector';

const ResetPasswordFormPage:React.FC<any> = ({updatePasswordAsync,getDataFromOtp,loading}) =>  {

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const validationSchema = yup.object({
    code: yup.string().required(" Le code OTP recu est obligatoire "),
    new_mot_passe: yup.string().required(" Le nouveau mot de passe est obligatoire "),
    email: yup.string().required(" L'email' est obligatoire "),
});

  const formik = useFormik({
      initialValues: {
          code: '',
          new_mot_passe: '',
          email: getDataFromOtp?.email,
          id: getDataFromOtp?.id,
      },
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values: any) => {
        values.code = values.code.toString()
        console.log('resetPasswordAsync data',values);
        
        updatePasswordAsync(values);
       
          if (!loading) {
            navigate(routePaths.auth,{replace:true});  
          }

        
      }
  });


  return (
    <form className="reset-password-container" onSubmit={formik.handleSubmit}>
      <div className="header-part">
      <div className='logo-part'>
          <img src={logoApp} alt="" className="logo-app" />
          <Button className='return-button' href={routePaths.authChildren.signInAbsolute}>
            <Icon icon="akar-icons:arrow-left" className='icon-return'/>
            Retour
          </Button>
          {/* <Link className="link-primary"
                to={routePaths.authChildren.signInAbsolute} >
                  <Icon icon="akar-icons:arrow-left" className='icon-return'/>
                  Retour
          </Link> */}
        </div>
        <span className="title">Réinitialisation de mot de passe</span>
        <span className="subtitle"> Votre nouveau mot de passe doit être different des mot de passes utilisés précédemment </span>
      </div>
      <div className="form-part">
          <TextField  placeholder="Code OTP" 
                       name="code"
                       label="code"
                       className='my-text-field'
                       onChange={formik.handleChange}
                       value={formik.values.code}
                       error={formik.touched.code && Boolean(formik.errors.code)}
                      type="number" />
          <TextField  placeholder="Mot de passe" 
                      variant="outlined"
                      name="new_mot_passe"
                       label="Mot de passe"
                       className='my-text-field'
                       onChange={formik.handleChange}
                       value={formik.values.new_mot_passe}
                       error={formik.touched.new_mot_passe && Boolean(formik.errors.new_mot_passe)}
                      type={showPassword ? "text" : "password"}
                      InputProps={{ 
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="ei:lock" className='icon-lock'/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      />
          <Button variant="contained" 
                  color='primary'
                  type='submit'
                  // href={routePaths.authChildren.signInAbsolute}
                  sx={{marginTop:'30px'}}>RÉINITIALISER LE MOT DE PASSE</Button>
      </div>
      {/* <div className="footer-part">
          <Link className="link-primary"
                to={routePaths.authChildren.signInAbsolute} > Connexion </Link>
      </div> */}
    </form>
  )
}

const mapStateToProps = createStructuredSelector({
  loading: selectAuthLoading,
  getDataFromOtp: getOtpData,
});

const mapDispatchToProps = (dispatch:any) => ({
  updatePasswordAsync: (data:any) => dispatch(updatePasswordAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const ResetPasswordForm = connector(ResetPasswordFormPage);

export default ResetPasswordForm;
