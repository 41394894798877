import { routePaths } from '@/config/routePaths';
import { InlineIcon } from '@iconify/react';
import { Badge, Tab, Tabs, tabsClasses, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Campaign.content.layout.styles.scss';
import { selectAlerteCountList, selectAlerteItemList } from "@/store/reducers/alerte/alerte.selector";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import Snackbar from '@/components/Snackbar/Snackbar';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';


const LCampaignPage: React.FC<any> = ({ alerte, alerteList,}) => {

  const theme = useTheme();
  const matches_down_xl = useMediaQuery(theme.breakpoints.down('xl'));
  const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    false && navigate(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute, { replace: true });

    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute) !== -1 && setValue(0);
    // location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute) !== -1 && setValue(1);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.cuveAbsolute) !== -1 && setValue(1);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.personnelsAbsolute) !== -1 && setValue(2);
    // location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.rapportTerrainAbsolute) !== -1 && setValue(4);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.enginsAbsolute) !== -1 && setValue(3);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.rapportEnginAbsolute) !== -1 && setValue(4);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.rapportPersonnelAbsolute) !== -1 && setValue(5);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className="l-campaign-content">
        <div className="l-campaign-content__sidebar">
          <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute}
            className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute) !== -1) ? " selected" : "")}>
            <InlineIcon icon="bx:home-alt" />
            Accueil
          </NavLink>
          
            {/* <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="clarity:list-line" />
              <Badge badgeContent={alerte?.fin_chantier || 0} color="warning" onClick={() => openNotification('bottomRight', 'chantier')}>
                Chantier
              </Badge>
            </NavLink> */}
            
            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.personnelsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.personnelsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="fa-solid:people-arrows" />

              <Badge badgeContent={alerte?.personnel || 0} color="warning" >
                Personnel
              </Badge>
              
            </NavLink>
          
            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.enginsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.enginsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="fa6-solid:people-group" />

              <Badge badgeContent={alerte?.vidange || 0} color="warning" >
              Engin
              </Badge>
              
            </NavLink>

            {/* <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.cuveAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.cuveAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="clarity:clipboard-outline-badged" />
                Cuve
            </NavLink> */}

            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.rapportEnginAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.rapportEnginAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="clarity:clipboard-outline-badged" />
                Rapport Engin
            </NavLink>

            {/* <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.rapportPersonnelAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.rapportPersonnelAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="clarity:clipboard-outline-badged" />
                Rapport Personnel
            </NavLink> */}
       


        </div>
        <div className="l-campaign-content__content">

          {
            (matches_down_xl || matches_down_lg) &&
            <>
              <div className="l-campaign-content__content__tabs-container">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="scrollable"
                  className="custom-tabs"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                >
                  <Tab label="Accueil"
                    icon={<InlineIcon icon="bx:home-alt" />}
                    onClick={() => {
                      navigate(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute, { replace: true });
                    }}
                    iconPosition="start" />
                 
                    <Tab label="Personnel"
                      icon={<InlineIcon icon="clarity:list-line" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.personnelsAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  
                  <Tab label="Engin"
                      icon={<InlineIcon icon="clarity:list-line" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.enginsAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  
                    {/* <Tab label="Cuve"
                      icon={<InlineIcon icon="clarity:clipboard-outline-badged" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.cuveAbsolute, { replace: true });
                      }}
                      iconPosition="start" /> */}
                 
                    <Tab label="Rapport Engin"
                      icon={<InlineIcon icon="fa-solid:people-arrows" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.rapportEnginAbsolute, { replace: true });
                      }}
                      iconPosition="start" />

                    {/* <Tab label="Rapport Personnel"
                      icon={<InlineIcon icon="fa6-solid:people-group" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.rapportPersonnelAbsolute, { replace: true });
                      }}
                      iconPosition="start" /> */}
                  

                </Tabs>
              </div>
            </>
          }

          <Outlet />
        </div>
      </div>
    </>
  )
}

const mapStateToProps: any = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
  permissions: selectUserProfil
});

const connector = connect(mapStateToProps);
const LCampaign = connector(LCampaignPage);

export default LCampaign;