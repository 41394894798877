import { ChantierService } from "@/services/chantier.service";
import { chantierTypes } from "./chantier.types";

export const setLoading = (loading:any) => ({
    type: chantierTypes.LOADING,
    payload: loading,
});

export const getAllChantiers = (list:any) => ({
    type: chantierTypes.GET_CHANTIER_LIST,
    payload: list,
});

export const selectedItem = (chantier:any) => ({
  type: chantierTypes.SELECTED_CHANTIER,
  payload: chantier,
});

export const selectListPersonnelChantier = (listPersonnel:any) => ({
  type: chantierTypes.GET_PERSONNEL_CHANTIER,
  payload: listPersonnel,
});

export const selectListEnginChantier = (listEngin:any) => ({
  type: chantierTypes.GET_ENGIN_CHANTIER,
  payload: listEngin,
});

export const selectSyntheseChantier = (synthese:any) => ({
  type: chantierTypes.GET_SYNTHESE_CHANTIER,
  payload: synthese,
});

export const selectHistoriqueChantier = (historique:any) => ({
  type: chantierTypes.GET_HISTORIQUE_CHANTIER,
  payload: historique,
});

 
export const  getAllChantiersAsync =  (id:number) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ChantierService.getChantiers(id);
};

export const  createChantierAsync =  (chantier:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ChantierService.createChantier(chantier);
};

export const  updateChantierAsync =  (chantier:any) =>
(dispatch:any) => {
  console.log("Chantier to service", chantier);
  dispatch(setLoading(true));
  ChantierService.updateChantier(chantier);
};

export const  deleteChantierAsync =  (id:any) =>
(dispatch:any) => {
  console.log("Chantier to service", id);
  
   ChantierService.deleteChantier(id);
   dispatch(setLoading(true));
};

export const  getPersonnelChantiersAsync =  (id: any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ChantierService.getPersonnelChantier(id);
};

export const  getEnginChantiersAsync =  (id: any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ChantierService.getEnginChantier(id);
};

export const  getSyntheseChantiersAsync =  (id: any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ChantierService.getSyntheseChantier(id);
};


export const  getHistoriqueChantiersAsync =  (data: any) =>
(dispatch:any) => {
  console.log('getHistoriqueChantiersAsync')
  dispatch(setLoading(true));
  const val = { 
    dateDebut:data.dateDebut,
    dateFin:data.dateFin,
    chantierId: (!data.chantierId || data.chantierId === '') ?  null: data.chantierId,
    enginId: (!data.enginId || data.enginId === '') ?  null: data.enginId,
  }
  console.log('getHistoriqueChantiersAsync', val)
  ChantierService.getHistoriqueChantier(val);
};


export const getHistoriqueChantiersListEnginAsync =  (data: any) =>
  async (dispatch:any) => {
    dispatch(setLoading(true));
    const val = { 
      dateDebut:data.dateDebut,
      dateFin:data.dateFin,
      chantierId: (!data.chantierId || data.chantierId === '') ?  null: data.chantierId,
    }
    const response: any = await ChantierService.getHistoriqueChantierListEngin(val);
    console.log('ChantiersListEngine ->', response)
    return response;  // Assurez-vous de retourner les données

  };

export const getHistoriqueChantiersListEnginAsync2 = (data: any) => async (dispatch: any) => {
    //dispatch(setLoading(true));
    const val = {
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        chantierId: (!data.chantierId || data.chantierId === '') ? null : data.chantierId,
    };

    try {
        const response: any = await ChantierService.getHistoriqueChantierListEngin2(val);
       // dispatch(setLoading(false));
        return response.data;  // Retourne les données
    } catch (error) {
        console.error(error);
       // dispatch(setLoading(false));
        throw error;
    }
};
