import { InlineIcon } from "@iconify/react";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./CreateCuveForm.style.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserItemList } from "@/store/reducers/user/user.selector";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
// import * as _ from 'lodash';
import moment from "moment";
import "dayjs/locale/fr";
import { selectEnginItemList } from "@/store/reducers/engin/engin.selector";
import { approvisionCuveAsync, retraitCuveAsync } from "@/store/reducers/cuve/cuve.action";



const CreateCuveFormPage: React.FC<any> = ({
  getEnginList,
  closeModal,
  cuveData,
  type,
  userConnected,
  retraitCuveAsync,
  approvisionCuveAsync
}) => {
  

  let enginList: any[] = [];
  getEnginList.forEach((x: any) => {
    enginList.push({ id: x?.engin?.id, value: x?.engin?.libelle + ' => ' + x?.engin?.num_parc });
  });

  const [engin, setEngin] = useState<{ id: any; value: string }>( { id: null, value: "" });
  const handleEnginChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    console.log(value);
    if (value === null) {
      setEngin({ id: null, value: "" });
    } else {
      setEngin(value as { id: any; value: string });
    }
  };

  const [date, setDate] = useState(moment().format("YYYY-MM-DD HH:m:s"));


  const validationSchema = yup.object({
    date: yup.string().required(" La date est obligatoire "),
    quantite: yup.string().required(" La quantite est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      cuveId: cuveData[0]?.id,
      enginId: '',
      nom_engin: engin?.value,
      date: date,
      chantierId: localStorage.getItem('chantierId'),
      quantite_cuve_avant: cuveData[0]?.quantite,
      quantite_cuve_apres: '',
      quantite: '',
      observation: '',
      utilisateurId: userConnected?.user?.id
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      console.log("data", values);
      //values.quantite_cuve_apres = cuveData[0]?.quantite + values.quantite;

      if (type === 'retrait') {
        delete values.id;
        retraitCuveAsync(values);
      } else {
        delete values.id;
        delete values.enginId;
        delete values.nom_engin;
        approvisionCuveAsync(values);
      }
      closeModal();
    },
  });

    // Mettre à jour automatiquement quantite_cuve_apres lorsque quantite change
    // useEffect(() => {
    //   if(type === 'retrait') {
    //     const quantiteCuveAvant = parseFloat(formik.values.quantite_cuve_avant);
    //   const quantite = parseFloat(formik.values.quantite || "0");
    //   const quantiteCuveApres = quantiteCuveAvant - quantite;
    //   formik.setFieldValue("quantite_cuve_apres", quantiteCuveApres);

    //   }else{
    //     const quantiteCuveAvant = parseFloat(formik.values.quantite_cuve_avant);
    //   const quantite = parseFloat(formik.values.quantite || "0");
    //   const quantiteCuveApres = quantiteCuveAvant + quantite;
    //   formik.setFieldValue("quantite_cuve_apres", quantiteCuveApres);

    //   }
      
    // }, [formik.values.quantite, formik.values.quantite_cuve_avant]);


    useEffect(() => {
      const quantiteCuveAvant = parseFloat(formik.values.quantite_cuve_avant);
      const quantite = parseFloat(formik.values.quantite || "0");
      let quantiteCuveApres = quantiteCuveAvant;

      const maxCapacity  = parseFloat(cuveData[0]?.capacite || "0");
  
      if (type === 'retrait') {
        quantiteCuveApres -= quantite;
        if (quantiteCuveApres < 0) {
          alert("La quantité après retrait ne peut pas être inférieure à zéro.");
          quantiteCuveApres = 0;
        }
      } else {
        quantiteCuveApres += quantite;
        if (quantiteCuveApres > maxCapacity) {
          alert("La quantité après approvisionnement ne peut pas dépasser la capacité de la cuve.");
          quantiteCuveApres = maxCapacity;
        }
      }
  
      formik.setFieldValue("quantite_cuve_apres", quantiteCuveApres);
    }, [formik.values.quantite, formik.values.quantite_cuve_avant]);

  return (
    <form className="create-cuve-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {" "}
        {type === 'retrait'
          ? "Retrait"
          : "Approvisionnement"}{" "}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>

        {type === 'retrait' && <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Autocomplete
            id="id-engin-select"
            options={enginList}
            value={engin}
            onChange={handleEnginChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Engin"
                label="Engin"
                variant="outlined"
                name="enginId"
                onChange={formik.handleChange}
                value={(formik.values.enginId = engin.id)}
              
                // helperText={formik.touched.enginId && formik.errors.enginId}
              />
            )}
          />
        </Grid>}

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date"
              value={date}
              onChange={(newValue: any) => {
                setDate(newValue);
                formik.setFieldValue(
                  "date_incident",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Quantite actuel Cuve"
            variant="outlined"
            name="quantite_cuve_avant"
            label="Quantite  Cuve"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.quantite_cuve_avant}
            InputProps={{ readOnly: true }} // Ajouter cette ligne pour le mode lecture seule

            error={
              formik.touched.quantite_cuve_avant &&
              Boolean(formik.errors.quantite_cuve_avant)
            }
            // helperText={formik.touched.quantite && formik.errors.quantite}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Quantite"
            variant="outlined"
            name="quantite"
            label="Quantite"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.quantite}
            error={
              formik.touched.quantite &&
              Boolean(formik.errors.quantite)
            }
            // helperText={formik.touched.quantite && formik.errors.quantite}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Quantité Après"
            variant="outlined"
            name="quantite_cuve_apres"
            label="Quantité Après"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.quantite_cuve_apres}
            InputProps={{ readOnly: true }} // Ajouter cette ligne pour le mode lecture seule

            error={
              formik.touched.quantite_cuve_apres &&
              Boolean(formik.errors.quantite_cuve_apres)
            }
            // helperText={formik.touched.quantite && formik.errors.quantite}
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Observation"
            variant="outlined"
            name="observation"
            label="Observation"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.observation}
            error={
              formik.touched.observation &&
              Boolean(formik.errors.observation)
            }
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>
        
      </Grid>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={
            <InlineIcon
              icon={"iconoir:save-floppy-disk"}
            />
          }
          variant="contained"
        >
          Valider
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            formik.resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  getUserList: selectUserItemList,
  getEnginList: selectEnginItemList,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  approvisionCuveAsync: (data: any) => dispatch(approvisionCuveAsync(data)),
  retraitCuveAsync: (data: any) => dispatch(retraitCuveAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateCuveForm = connector(CreateCuveFormPage);

export default CreateCuveForm;
