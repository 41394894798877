import { UserService } from "@/services/user.service";
import { userTypes } from "./user.types";

export const setLoading = (loading:any) => ({
    type: userTypes.LOADING,
    payload: loading,
});

export const getAllUsers = (list:any) => ({
    type: userTypes.GET_USER_LIST,
    payload: list,
});

export const selectedItem = (user:any) => ({
  type: userTypes.SELECTED_USER,
  payload: user,
});

 
export const  getAllUsersAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  UserService.getUsers();
};

export const  createUserAsync =  (user:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  UserService.createUser(user);
};

export const  updateUserAsync =  (user:any) =>
(dispatch:any) => {
  console.log("user to service", user);
  dispatch(setLoading(true));
  UserService.updateUser(user);
};

export const  deleteUserAsync =  (data:any) =>
(dispatch:any) => {
  console.log("user to service", data);
  dispatch(setLoading(true));
  UserService.deleteUser(data);
};

export const  editUserConnectedProfilAsync =  (data:any) =>
(dispatch:any) => {
  console.log("user to service", data);
  dispatch(setLoading(true));
  UserService.deleteUser(data);
};

export const  editUserPasswordAsync =  (data:any) =>
(dispatch:any) => {
  console.log("user to service", data);
  dispatch(setLoading(true));
  UserService.editUserPassword(data);
};




